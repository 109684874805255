import { Reducer } from 'redux';
import { SignUpAction, SignUpState, SignUpTypes } from './types';

const INITIAL_STATE: SignUpState = {
  loading: false,
};

const reducer: Reducer<SignUpState> = (
  state = INITIAL_STATE,
  action: SignUpAction,
) => {
  switch (action.type) {
    case SignUpTypes.START_LOADING:
      return { ...state, loading: true };
    case SignUpTypes.STOP_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
