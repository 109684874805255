import React, { SelectHTMLAttributes, useCallback } from 'react';
import { BaseFieldProps, Field } from 'redux-form';
import { Container } from './styles';

interface Props extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'type'> {
  name: string;
  id: string;
  label?: string;
}

const Select: React.FC<Props & BaseFieldProps> = ({
  className,
  label,
  id,
  children,
  ...rest
}) => {
  const renderField: BaseFieldProps = useCallback(
    ({ input, meta: { dirty, error, warning }, ...rest }) => (
      <>
        <select {...input} {...rest} />
        {dirty &&
          ((error && <div className="alert alert-danger">{error}</div>) ||
            (warning && <div className="alert alert-warning">{warning}</div>))}
      </>
    ),
    [],
  );

  return (
    <Container className={className}>
      {label && (
        <label htmlFor={id} className="register-label">
          {label}
        </label>
      )}
      <Field
        {...rest}
        component={renderField}
        type="select"
        id={id}
        className="form-control register-placeholder"
      >
        {children}
      </Field>
    </Container>
  );
};

export default Select;
