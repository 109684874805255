import React, { Component } from "react";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { ADDITIONAL_LESSONS, WATCH } from "../../../constants/routes";
import MainHeader from "../MainHeader";
import withAuthorization from "../Session/withAuthorization";
import { auth, db } from "../../../firebase";
import VOLTAR from "../Images/Voltar.png";
import _ from 'lodash';
import swal from 'sweetalert';
import Checkbox from '@material-ui/core/Checkbox';
import blue from '@material-ui/core/colors/blue';
import * as routes from "../../../constants/routes";
import ReactStars from 'react-stars';
import Loading from "../Loading";
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from "jquery";
import { shade } from 'polished';

class ThemeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: auth.getAuthUser(),
      theme: [],
      classes: {},
      userEmail: "",
      checkBox: [],
      rating: {},
      isLoading: true,
      mailLoading: false,
      preSignup: true,
      allowedOnly: [],
    }
  }

  componentDidMount() {
    this.getUserEmail();
    const { history } = this.props;
    if (this.props.propsState && this.props.propsState.theme) {
      this.setState({ theme: this.props.propsState.theme }, () => {
        this.getClasses();
      });
    } else {
      history.push(routes.ADDITIONAL_LESSONS);
    }
  }

  getClasses() {
    const themeId = this.state.theme.uid;
    db.refNode(`Classes/`).orderByChild("themeId").equalTo(themeId).once("value").then(classes => {
      if (classes.val()) {
        console.log('classes', classes.val())
        let data = _.filter(classes.val(), { classType: "theme" });
        console.log('data', data)
        data = _.orderBy(data, "className", 'asc');
        if (_.size(data) > 0) {
          _.each(data, (classes) => {
            this.setState(prevState => ({
              classes: {
                ...prevState.classes,
                [classes.uid]: classes
              }
            }), () => {
              this.isChecked();
              _.each(data, (classData) => {
                this.setTotal(classData);
              });
            });
          });
          this.setState({ classesBackup: data });
        } else {
          swal({
            text: "Sem aulas para exibir!",
            icon: "error",
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
          this.setState({ isLoading: false });
        }
      } else {
        swal({
          text: "Sem aulas para exibir!",
          icon: "error",
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: true,
        });
        this.setState({ isLoading: false });
      }
    }).catch(() => {
      swal({
        text: "Erro ao carregar aulas",
        icon: "error",
        buttons: {
          cancel: false,
          confirm: true,
        },
        dangerMode: true,
      });
    });
  }

  getUserEmail() {
    let userUid = auth.getAuthUser().uid;
    db.refNode(`UsersBB/${userUid}/`).once("value").then((userData) => {
      if (userData.val()) {
        const user = userData.val();
        this.setState({
          userEmail: user.email,
          preSignup: !!user.preSignup,
          allowedOnly: !!user.preSignup ? ["-NQfeg7G8fVz3Zhiq4-8", "-NQfelGSko0HV6YmIy2R"] : [],
        });
      }
    }).catch((error) => {
      // console.log("Error", error);
    });
  }

  exportPDF(email, pdf) {
    this.setState({ mailLoading: true }, () => {
      $.ajax({
        type: "GET",
        url:
          "https://us-central1-capacitampe-b1bf8.cloudfunctions.net/functionMail",
        data: {
          email: email,
          pdf: pdf
        },
        dataType: "JSON",
        success: () => {
          swal({
            text: "Email enviado com sucesso!",
            icon: "success",
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
        },
        error: () => {
          swal({
            text: "Erro ao enviar email!",
            icon: "error",
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
        },
        complete: () => {
          this.setState({ mailLoading: false });
        }
      });
    });
  }

  isChecked = () => {
    let userUid = auth.getAuthUser().uid;
    const checked = [];
    db.refNode(`UsersBB/${userUid}/ratedClasses`).once("value").then((ratedClasses) => {
      const _ratedClasses = ratedClasses.val();
      _.each(this.state.classes, (classData) => {
        const uid = classData.uid;
        if (_ratedClasses && _ratedClasses[uid] != undefined) {
          checked.push({ uid: uid, checked: _ratedClasses[uid] })
        } else {
          checked.push({ uid: uid, checked: false })
        }
      })
      // console.log(checked);
      this.setState({
        checkBox: checked
      });
    });
  }


  setTotal(ratingData) {
    let ratingSumContent = ratingData.ratingSumContent ? ratingData.ratingSumContent : 0;
    let ratingSumTeacher = ratingData.ratingSumTeacher ? ratingData.ratingSumTeacher : 0;
    let ratingSumKnowledge = ratingData.ratingSumKnowledge ? ratingData.ratingSumKnowledge : 0;
    let ratingCountContent = ratingData.ratingCountContent ? ratingData.ratingCountContent : 0;
    let ratingCountTeacher = ratingData.ratingCountTeacher ? ratingData.ratingCountTeacher : 0;
    let ratingCountKnowledge = ratingData.ratingCountKnowledge ? ratingData.ratingCountKnowledge : 0;
    const totalSumRating = (ratingSumContent / ratingCountContent) + (ratingSumKnowledge / ratingCountKnowledge) + (ratingSumTeacher / ratingCountTeacher);
    let average = totalSumRating / 3;
    if (ratingSumContent == 0 || ratingSumTeacher == 0 || ratingSumKnowledge == 0 || ratingCountContent == 0 || ratingCountTeacher == 0 || ratingCountKnowledge == 0) {
      average = 0;
    }
    const ranking = (ratingCountTeacher + ratingCountContent + ratingCountKnowledge) / 3;
    this.setState(prevState => ({
      rating: {
        ...prevState.rating,
        [ratingData.uid]: {
          ratingAverage: average.toFixed(2),
          ratingRanking: ranking
        }
      }
    }), () => {
      this.setState({ isLoading: false });
    });
  }

  handleSearchTeacher(e) {
    const text = e.target.value;
    if (text === "") {
      this.setState({ classes: this.state.classesBackup, teacherSearch: text });
    } else {
      const classesReturned = _.filter(this.state.classes, (classData) => {
        if (_.includes(_.lowerCase(classData.teacherName), _.lowerCase(text))) {
          return classData;
        }
      })
      this.setState({ classes: classesReturned, teacherSearch: text });
    }
  }

  render() {
    const { classes } = this.props;
    const allowed = this.state.preSignup ? this.state.allowedOnly.includes(this.state.theme.uid) : true;
    return (
      this.state.isLoading ?
        <Loading />
        :
        <div className="container-fluid">
          <div className="background">
            <MainHeader logado={this.state.currentUser ? true : false} />
            <div className="col-xs-12 col-md-10 col-md-offset-1">
              <div className="col-md-8">
                <Link to={["-NQfeg7G8fVz3Zhiq4-8", "-NQfelGSko0HV6YmIy2R"].includes(this.state.theme.uid) ? routes.MAIN_SCREEN : ADDITIONAL_LESSONS}>
                  <div className="col-lg-1 col-md-2 col-xs-3">
                    <img
                      className="img-responsive center-block image-back"
                      src={VOLTAR}
                    />
                  </div>
                  <u className={classes.link}>Voltar</u>
                </Link>
              </div>
            </div>
            <div className="col-md-10 col-md-offset-1 col-xs-12" style={{ textAlign: 'center', marginTop: "2%" }}>
              <span className="responsive-title">{this.state.theme.themeName}</span><br />
            </div>
            <div className="col-xs-12 col-md-10 col-md-offset-1" style={{ marginTop: "3%" }}>
              <div className="row">
                <div className="col-md-5 col-xs-12" style={{ marginLeft: "3%" }}>
                  <input
                    id="search"
                    value={this.state.teacherSearch}
                    name="teacherSearch"
                    onChange={(e) => this.handleSearchTeacher(e)}
                    placeholder="Pesquisar por consultor"
                    type="search"
                    className="form-search"
                  />
                  <div id="lupa" />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-md-offset-8 lg-div" style={{ textAlign: 'right', marginTop: "2%" }}>
              <span className="concluida">Aula concluída</span>
            </div>
            <div className="col-md-10 col-md-offset-1 col-xs-12">
              {
                _.map(this.state.classes, (classData) => {
                  let modalId = `#${classData.uid}`;
                  return (
                    <div className="col-md-12 col-xs-12" style={{ marginTop: "5%" }}>
                      <div className="col-md-4 col-xs-12">
                        <img
                          className="img-responsive center-block classImg imgRelated"
                          src={classData.classPhotoURL}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12 mobile-div" style={{ textAlign: "left" }}>
                        <span className="title-min">{classData.className.toUpperCase()}</span><br />
                        {/*<span className="text-min" style={{ marginTop: "5%" }} >{classData.classDesc}</span>*/}
                        <div className="col-md-12 row" style={{ marginTop: "5%" }}>
                          {this.state.rating[classData.uid] ?
                            <div>
                              <div className="col-md-6 col-xs-12 row">
                                <ReactStars count={5} size={30} value={this.state.rating[classData.uid].ratingAverage} edit={false} color2={'#ffd700'} />
                              </div>
                              <div className="col-md-6 col-xs-12 row" style={{ marginTop: "3%" }}>
                                <span className="rating-text">{this.state.rating[classData.uid].ratingAverage} ({this.state.rating[classData.uid].ratingRanking} classificações)</span>
                              </div>
                            </div>
                            : ""}
                        </div>
                        <div className="col-md-12 row" style={{ marginTop: "5%" }}>
                          <div className="col-md-5 col-xs-12 row">
                            {allowed ? (
                              <Link to={`${WATCH}/?id=${classData.uid}`}>
                                <div className="col-md-12 col-xs-12 assistir-aula-min clickable mobile-div">
                                  <i className="fas fa-play icon-middle"></i>&nbsp;&nbsp;<span>Assistir Aula</span>
                                </div>
                              </Link>
                            ): (
                              <div className="col-md-12 col-xs-12 assistir-aula-min clickable mobile-div" style={{ background: shade(0.2)('#fff') }}>
                                <i className="fas fa-play icon-middle"></i>&nbsp;&nbsp;<span>Assistir Aula</span>
                              </div>
                            )}
                          </div>
                          <div className="col-md-5 col-md-offset-1 col-xs-12 row">
                            {allowed ? (
                              <div data-toggle="modal" data-target={modalId} className="col-md-12 col-xs-12 guia-de-referencia-min clickable mobile-div">
                                <i className="far fa-folder icon-middle"></i>&nbsp;&nbsp;<span>Referência</span>
                              </div>
                            ) : (
                              <div className="col-md-12 col-xs-12 guia-de-referencia-min clickable mobile-div" style={{ background: shade(0.2)('#057568') }}>
                                <i className="far fa-folder icon-middle"></i>&nbsp;&nbsp;<span>Referência</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-xs-3 mobile-div">
                        <MuiThemeProvider theme={theme}>
                          {this.renderDone(classes, classData.uid)}
                        </MuiThemeProvider>
                      </div>
                      {this.renderModal(classData.guideURL, classData.uid, classes)}
                    </div>
                  );
                })
              }
            </div>
            <div className="col-xs-12 col-md-10 col-md-offset-1" style={{ marginBottom: '2%', marginTop: '3%' }}>
              <div className="col-md-8">
                <Link to={ADDITIONAL_LESSONS}>
                  <div className="col-lg-1 col-md-2 col-xs-3">
                    <img
                      className="img-responsive center-block image-back"
                      src={VOLTAR}
                    />
                  </div>
                  <u className={classes.link}>Voltar</u>
                </Link>
              </div>
            </div>
          </div>
        </div>
    );
  }

  renderDone(classes, classUid) {
    // console.log(this.state.checkBox)
    let check = this.state.checkBox.find(ratedClass => ratedClass.uid == classUid);
    if (!check) {
      check = false;
    }
    return <Checkbox
      id="fadf"
      className={classes.size}
      color="primary"
      checked={!!check.checked}
    />;
  }

  renderModal(URL, id, classes) {
    return <div id={id} className="modal fade" tabindex="-1" role="dialog">
      <div id="print-area" className="modal-dialog big-modal" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button id="modalClose" type="button" className="close not-print" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <p id="print-title" className="title-modal-min modal-title">Guia de Referência</p>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 modal-color-icon row">
                  <div className="col-md-12 col-xs-12 mobile-div">
                    {!this.state.mailLoading ?
                      <div className="clickable" onClick={() => { this.exportPDF(this.state.userEmail, URL) }}>
                        <i className="far fa-envelope modal-icon"></i>&nbsp;&nbsp;<span className="modal-text-icon">Enviar por email ({this.state.userEmail})</span>
                      </div>
                      :
                      <div className="text-center">
                        <CircularProgress size={40} className={classes.progress} />
                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-12 guia-text">
                  <object className="pdf-iframe" data={URL} type="application/pdf">
                    <iframe src={URL}></iframe>
                  </object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

}

const theme = createMuiTheme({
  palette: {
    primary: blue
  }
});

const styles = {
  root: {
    flex: 1
  },
  body: {
    fonWeight: 100
  },
  link: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "20pt",
    color: 'black'
  },
  size: {
    width: 25,
    height: 25,
  },
  progress: {
    color: '#17549B'
  }
};

// const authCondition = (authUser) => !!authUser;
// ThemeDetails = withAuthorization(authCondition)(ThemeDetails);
ThemeDetails = withStyles(styles)(ThemeDetails);
export default ThemeDetails;
