import { ActionType } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

/*
  ACTION TYPES
*/
export enum SignUpTypes {
  START_LOADING = '@signUp/START_LOADING',
  STOP_LOADING = '@signUp/STOP_LOADING',
}

export type SignUpAction = ActionType<typeof import('./actions')>;

/*
  DATA TYPES
*/

/*
  STATE TYPES
*/

export type SignUpState = DeepReadonly<{
  loading: boolean;
}>;
