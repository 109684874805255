import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { InjectedFormProps } from 'redux-form';
import PersonalData from './components/PersonalData';
import AuthData from './components/AuthData';

import { Container } from './styles';

interface Props extends InjectedFormProps<{ registerDate: string }> {
  type: string;
  submit(data: Record<string, any>): void;
}

const PreSignUpComponent: React.FC<Props> = ({
  type,
  submit,
  handleSubmit,
  submitting,
  invalid,
}) => {
  return (
    <Container className="container-fluid">
      <div className="background">
        <div className="row">
          <div
            className="col-md-12"
            style={{ textAlign: 'center', marginTop: '3%' }}
          >
            <span className="title">
              Cadastro de dados - {type.includes('PR') ? 'PR' : type}
            </span>
          </div>
          <form id="register-form" onSubmit={handleSubmit(submit)}>
            <PersonalData />
            <hr />
            <AuthData />
            <div
              className="row"
              style={{ marginBottom: '10%', marginTop: '10%' }}
            >
              <div className="col-md-8 col-md-offset-4">
                <button
                  id="btn-salvar-dados"
                  type="submit"
                  className="btn btn-default btn-lg col-lg-5 col-md-4 col-xs-12 cursor-pointer"
                  disabled={submitting || invalid}
                >
                  <div id="btn-text">SALVAR DADOS</div>
                  <div id="preloader" className="div-progress">
                    <CircularProgress className="progress" />
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default PreSignUpComponent;
