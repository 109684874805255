import React from 'react';
import Input from 'src/components/Input';
import { required } from 'src/utils/validations';

interface Props {}

export interface CnpjData {
  companyName: string;
  cep: string;
  complement: string;
  number: string;
  CNAE: string;
}

const PersonalData: React.FC<Props> = () => {
  return (
    <>
      <div className="row">
        <Input
          className="form-group col-md-4"
          type="date"
          name="registerDate"
          id="register-date"
          disabled
          label="Data do Cadastro"
          validate={[required]}
        />

        <Input
          className="form-group col-md-10"
          label="Nome Completo"
          name="name"
          id="register-name"
          placeholder=" Ex: Ricardo Vieira Lopes Mendes"
          validate={[required]}
        />
      </div>
    </>
  );
};

export default PersonalData;
