import React, { Component } from "react";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { MAIN_SCREEN, WATCH, CERTIFICATE } from "../../../constants/routes";
import MainHeader from "../MainHeader";
import { auth, db } from "../../../firebase";
import VOLTAR from "../Images/Voltar.png";
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Loading from "../Loading";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import moment from "moment";
import swal from 'sweetalert';
import { green, blue } from '@material-ui/core/colors';

class ViewdLessons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: auth.getAuthUser(),
            viewed: {},
            themes: {},
            classes: {},
            checkBox: {},
            duration: {},
            totalClassHours: {},
            isLoading: true,
            certificateLoading: false,
            checkIsLoad: {},
            loadingCheck: {},
            loadClass: {},
            fullWatched: {},
        }
    }

    componentDidMount() {
        this.setLessons();
    }

    async setLessons() {
      let userUid = auth.getAuthUser().uid;
      let keys = {};
      const _user = await db.refNode(`UsersBB/${userUid}/`).once("value");
      const user = _user.val();
      const ratedClasses = user.ratedClasses;
      this.setState({
        checkBox: ratedClasses
      })
      if (ratedClasses) {
        for (let key in ratedClasses) {
          if (!keys[key]) {
            const _classData = await db.refNode(`Classes/${key}/`).once("value");
            const classData = _classData.val();
            if (classData) {
              const themeId = classData.themeId;
              if (themeId) {
                const _themeData = await db.refNode(`Themes/${themeId}`).once("value");
                const themeData = _themeData.val()
                const _classes = await db.refNode(`Classes/`).orderByChild("themeId").equalTo(themeId).once("value");
                const classes = _classes.val();
                _.each(classes, (_class, key) => keys = {...keys, [key]: true});
                this.setState(prevState => ({
                    themes: {
                        ...prevState.themes,
                        [themeId]: {
                            uid: themeId,
                            themeName: themeData.themeName
                        }
                    },
                    classes: {
                        ...prevState.classes,
                        [themeId]: classes
                    }
                }), () => {
                  const fullWatched = this.fullWatched(classes);
                  this.setState(prevState => ({
                    fullWatched: {
                        ...prevState.fullWatched,
                        [themeId]: fullWatched
                    },
                  }));
                });
              }
            }
          }
        }
        await this.getQuestionsClasses(user, ratedClasses);
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    }

    async getQuestionsClasses(user, ratedClasses) {
        if (user) {
            const {accountType, email} = user;
            var type;
            if(accountType == "Futuro Empreendedor" || accountType == "Micro Empreendedor Individual" || accountType == "Produtor Rural")
            type = "PF"
            else
            type = "PJ"

            this.setState({
                userType: type,
                userEmail: email
            });
            await this.setTypeQuestions(accountType);
            this.getClasses(ratedClasses);
        }
    }

    async setTypeQuestions(type) {
        if (type == "Futuro Empreendedor" || type == "Micro Empreendedor Individual" || type == "Produtor Rural") {
          const questions = await db.refNode(`Questions/PF`).once("value")
          if (questions.val()) {
              this.setState({ questions: questions.val() });
          }
        } else {
          const questions = await db.refNode(`Questions/PJ`).once("value")
          if (questions.val()) {
              this.setState({ questions: questions.val() });
          }
        }
    }

    async getClasses() {
      const classes = await db.refNode(`Classes/`).orderByChild("classType").equalTo("question").once("value")
      if (classes.val()) {
          this.filterClasses(classes.val());
      }
    }

    filterClasses(classData){
        let array = _.values(classData);
        let type = this.state.userType;
        let classes = []
        _.each(this.state.questions, (filter, key) =>{
            let data = _.filter(array, {questionId: `${key}`, themeType: type});
            if(_.size(data) > 0){
                classes = [...classes, ..._.values(data)];
            }
        });
        const fullWatched = this.fullWatched(classes);
        this.setState(prevState => ({
            classes: {
                ...prevState.classes,
                [type]: classes
            },
            themes: {
                ...prevState.themes,
                [type]: {
                    uid: type,
                    themeName: 'Questionário'
                }
            },
            fullWatched: {
              ...prevState.fullWatched,
              [type]: fullWatched
            },
        }));
    }



    // getThemes() {
    //     _.each(this.state.viewed, (viwedTheme) => {
    //         const themeUid = viwedTheme.uid;
    //         db.refNode(`Themes/${themeUid}`).once("value").then(themeData => {
    //             const thisTheme = themeData.val()
    //             if (thisTheme) {
    //                 const themeId = thisTheme.uid;
    //                 db.refNode(`Classes/`).orderByChild("themeId").equalTo(themeId).once("value").then(classData => {
    //                     const classes = classData.val();
    //                     if (classes) {
    //                         this.fullWatched(classes)
    //                         this.setState(prevState => ({
    //                             themes: {
    //                                 ...prevState.themes,
    //                                 [themeId]: {
    //                                     uid: themeId,
    //                                     themeName: thisTheme.themeName
    //                                 }
    //                             },
    //                             classes: {
    //                                 ...prevState.classes,
    //                                 [themeId]: classes
    //                             }
    //                         }), () => {
    //                             this.isChecked(themeId);
    //                             const fullWatched = this.fullWatched(classes);
    //                             this.setState(prevState => ({
    //                                 isLoading: false,
    //                                 fullWatched: {
    //                                     ...prevState.fullWatched,
    //                                     [themeId]: fullWatched
    //                                 },
    //                                 checkBox: [
    //                                     ...prevState.checkBox,
    //                                     ...ratedClasses
    //                                 ]
    //                             }));
    //                         });
    //                     } else {
    //                         this.setState(prevState => ({
    //                             themes: {
    //                                 ...prevState.themes,
    //                                 [themeId]: {
    //                                     uid: themeId,
    //                                     themeName: thisTheme.themeName
    //                                 }
    //                             },
    //                             fullWatched: {
    //                                 ...prevState.fullWatched,
    //                                 [themeId]: true
    //                             },
    //                         }), () => {
    //                             this.setState({ isLoading: false });
    //                         });
    //                     }
    //                 });
    //             }
    //         });
    //     });
    // }

    // loadClass = (themeId) => {
    //     if (!this.state.loadClass[themeId]) {
    //         this.setState(prevState => ({
    //             loadClass: {
    //                 ...prevState.loadClass,
    //                 [themeId]: false
    //             }
    //         }), () => this.getClass(themeId));
    //     }
    // }

    // getClass = (themeId) => {
    //     db.refNode(`Classes/`).orderByChild("themeId").equalTo(themeId).once("value").then(classData => {
    //         if (classData.val()) {
    //             this.setState(prevState => ({

    //             }), () => {
    //                 this.setState(prevState => ({
    //                     loadClass: {
    //                         ...prevState.loadClass,
    //                         [themeId]: true
    //                     }
    //                 }), () => {

    //                 })
    //             });
    //         }
    //     });
    // }

    // isChecked = (themeId) => {
    //     if (themeId) {
    //         if (!this.state.checkIsLoad[themeId]) {
    //             this.setState(prevState => ({ loadingCheck: { ...prevState.loadingCheck, [themeId]: true } }), () => {
    //                 let userUid = auth.getAuthUser().uid;
    //                 _.each(this.state.classes[themeId], (classData) => {
    //                     db.refNode(`UsersBB/${userUid}/watchedClasses/${classData.themeId}/${classData.uid}`).once("value").then((checked) => {
    //                         if (checked.val()) {
    //                             this.handleCheckBox(classData.uid, true, themeId);
    //                         } else {
    //                             this.handleCheckBox(classData.uid, false, themeId);
    //                         }
    //                     });
    //                 });
    //             });
    //         }
    //     }
    // }

    // handleCheckBox(classData, ratedClasses) {

    //     this.setState(prevState => ({
    //         checkBox: {
    //             ...prevState.checkBox,
    //             [key]: value
    //         }
    //     }), () => this.setState(
    //         prevState => ({
    //             loadingCheck: {
    //                 ...prevState.loadingCheck,
    //                 [themeId]: false
    //             },
    //             checkIsLoad: {
    //                 ...prevState.checkIsLoad,
    //                 [themeId]: true
    //             }
    //         }))
    //     );
    // }

    fullWatched = (classes) => {
        let fullWatched = true;
        _.map(classes, (classData) => {
            if (!this.state.checkBox[classData.uid]) {
                fullWatched = false;
            }
        });
        return fullWatched;
    }

    getVideoId = (url) => {
        if (!url) return;
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        let match = url.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return "error";
        }
    }

    sendCertificate(theme, classData) {
        this.setState({ certificateLoading: true });
        let totalClassHours = 0;
        for(const [key, value] of Object.entries(classData)) {
            const totalClassHour = value.totalClassHours
            if(totalClassHour) {
                const splited_totalClassHours = totalClassHour.split(':');
                totalClassHours += parseInt(splited_totalClassHours[0]); // minutes
                totalClassHours += parseInt(splited_totalClassHours[1])/60; // seconds to minutes
            }
        }
        let totalHour = 0;
        let totalMinutes = 0;
        if (totalClassHours >= 60) {
            totalHour = parseInt(totalClassHours / 60);
            totalMinutes = parseInt(totalClassHours % 60);
        } else {
            totalMinutes = parseInt(totalClassHours);
        }


        let hour = "";
        if (totalHour) {
            hour = `${totalHour} ${totalHour > 1? 'horas' : 'hora'} e ${totalMinutes} ${totalMinutes > 1? 'minutos' : 'minuto'}`;
        } else {
            hour = `${totalMinutes} ${totalMinutes > 1? 'minutos' : 'minuto'}`;
        }

        const userUid = auth.getAuthUser().uid;
        db.refNode(`UsersBB/${userUid}/`).once("value").then(snapshot => {
            const user = snapshot.val();
            const certificateUid = theme.uid + userUid;
            if (!user.certificates || !user.certificates[certificateUid]) {
              const certificateData = {
                hour: hour,
                course: theme.themeName,
                date: moment().format('DD/MM/YYYY')
              }
              db.refNode(`UsersBB/${userUid}/certificates/${certificateUid}`).set(certificateData);
            }
            const data = {
                userUid: userUid,
                certificateUid: certificateUid,
                certificateName: `Certificado de Conclusão - ${theme.themeName}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
            }
            axios.post(
                // 'http://localhost:5000/alunocapacita/us-central1/generateCertificate', // @debug
                'https://us-central1-bbcapacitampe-a5cf6.cloudfunctions.net/generateCertificate/',
                data,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": 'application/json'
                    },
                    crossdomain: true
                }
            ).then((res => {
                this.setState({ certificateLoading: false });
                swal({
                    text: "Certificado enviado com sucesso! Verifique o email cadastrado.",
                    icon: "success",
                    dangerMode: true,
                });
            })).catch(error => {
                this.setState({ certificateLoading: false });
                swal({
                    text: "Erro ao enviar o certificado, entre em contato com o nosso suporte!",
                    icon: "error",
                    dangerMode: true,
                });
            });
        })
    }

    render() {
        const { classes } = this.props;
        return (
            this.state.certificateLoading ?
                <Loading />
                :
                <div className="container-fluid">
                    <div className="background">
                        <MainHeader  logado={this.state.currentUser ? true : false}/>
                        <div className="col-xs-12 col-md-10 col-md-offset-1">
                            <div className="col-md-8">
                                <Link to={MAIN_SCREEN}>
                                    <div className="col-lg-1 col-md-2 col-xs-3">
                                        <img
                                            className="img-responsive center-block image-back"
                                            src={VOLTAR}
                                        />
                                    </div>
                                    <u className={classes.link}>Voltar</u>
                                </Link>
                            </div>
                        </div>
                        {this.state.isLoading && <Loading />}
                        <div className="col-md-10 col-md-offset-1 col-xs-12" style={{ textAlign: 'center', marginTop: "2%" }}>
                            <span className="responsive-title">VÍDEO AULAS ASSISTIDAS</span><br />
                        </div>
                        <div className="col-md-10 col-md-offset-1 col-xs-12" style={{...styles.certificateText, marginTop: '2%'}}>
                            <span>Prezado aluno, a certificação se dará da seguinte forma:</span>
                            <ol>
                                <li>Você deverá assistir a aula até o final e realizar a avaliação da aula, respondendo as 3 perguntas e clicar em SALVAR.</li>
                                <li>O sistema enviará o certificado quando solicitado para o seu email</li>
                            </ol>
                            <span>O certificado será enviado somente nesses casos:</span>
                            <ol>
                                <li>Quando o aluno assistir à todas as aulas do questionário.</li>
                                <li>Quando o aluno assistir a todas as aulas de cada curso das aulas complementares. Ex: Alinhamento de sócio, Atendimento, Empreendedorismo e etc.</li>
                            </ol>

                            <Link
                                to={CERTIFICATE}>
                                <button
                                    id="btn-certificado2"
                                    className="btn btn-default btn-lg col-lg-3 col-md-3 col-xs-12 clickable">
                                    Saiba como emitir<br/> o certificado
                                </button>
                            </Link>
                        </div>
                        <div className="col-md-2 col-md-offset-9 lg-div" style={{ marginTop: "3%" }}>
                            <span className={classes.textViewed}>Aula concluída</span><br /><br />
                        </div>
                        {_.map(this.state.themes, (theme, key) => {
                            let target = `#${key}`;
                            return (
                                <div className="col-xs-12 col-md-10 col-md-offset-1 lessons-viewd-card">
                                    <div className="col-md-10 col-xs-10">
                                        <div className="col-md-7 col-xs-12">
                                            <span className={classes.text}>{theme.themeName}</span>
                                        </div>
                                        <div className="col-md-5 col-xs-12">
                                            <MuiThemeProvider theme={themeUi}>
                                                <Button
                                                    id="emitir"
                                                    className={classes.certificate}
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled= { !this.state.fullWatched[key] }
                                                    onClick={() => this.sendCertificate(theme, this.state.classes[key])}>
                                                    Enviar Certificado!
                                                </Button>
                                            </MuiThemeProvider>
                                        </div>

                                    </div>
                                    <div className="col-md-1 col-md-offset-1 col-xs-2">
                                        <h3 className="arrow-toggle clickable collapsed text-dashboard"
                                            data-toggle="collapse"
                                            data-target={target}
                                            aria-expanded="false">
                                        </h3>
                                    </div>
                                    <div id={key} className="col-md-10 col-md-offset-1 col-xs-12 collapse step-div">
                                        {
                                            // !this.state.loadClass[key] ?
                                            //     <div className="col-md-12">
                                            //         <LinearProgress classes={{ colorPrimary: classes.progressLine, barColorPrimary: classes.barColorPrimary }} />
                                            //     </div>
                                            //     :
                                                _.map(this.state.classes[key], (classData) => {
                                                    let className = classData.className;
                                                    if (className.length > 55) {
                                                        className = className.substring(0, 55) + '...';
                                                    }
                                                    return (
                                                        <div className="step">
                                                            <div>
                                                                <div className="circle-step"></div>
                                                            </div>
                                                            <div>
                                                                <div className="title-step col-md-10">
                                                                    <Link to={{ pathname: WATCH, state: { lesson: classData } }}>
                                                                        {className}<span className="step-duration">&nbsp;({classData.totalClassHours ? classData.totalClassHours : "00:00"})</span>
                                                                    </Link>
                                                                </div>
                                                                <div className="col-md-1 col-md-offset-1">
                                                                    {
                                                                        // this.state.loadingCheck[classData.themeId] ?
                                                                        //     <CircularProgress size={25} className={classes.progress} />
                                                                        //     :
                                                                            <MuiThemeProvider theme={themeUi}>
                                                                                <Checkbox
                                                                                    className={classes.size}
                                                                                    color="primary"
                                                                                    checked={this.state.checkBox[classData.uid]}
                                                                                />
                                                                            </MuiThemeProvider>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

        );
    }
}
const styles = {
    root: {
        flex: 1
    },
    body: {
        fonWeight: 100
    },
    certificateText: {
        fontFamily: "Gotham Rounded Medium",
        fontSize: "11pt",
        color: '#17549B'
    },
    text: {
        fontFamily: "Gotham Rounded Medium",
        fontSize: "16pt",
        color: '#17549B'
    },
    textViewed: {
        fontFamily: "Gotham Rounded Medium",
        fontSize: "9pt",
        color: '#FA818A'
    },
    link: {
        fontFamily: "Gotham Rounded Book",
        fontSize: "20pt",
        color: 'black'
    },
    media: {
        height: 200
    },
    size: {
        width: 25,
        height: 25,
    },
    progress: {
        color: '#17549B'
    },
    progressLine: {
        backgroundColor: '#17549B'
    },
    barColorPrimary: {
        backgroundColor: '#EAF5FD',
    },

    certificate: {
        fontFamily: "Gotham Rounded Medium",
        fontSize: "16pt",
        left: "40%"
    }
};

const themeUi = createMuiTheme({
    palette: {
        primary: blue,
        secondary: green,
    }
});

// const authCondition = (authUser) => !!authUser;
// ViewdLessons = withAuthorization(authCondition)(ViewdLessons);
ViewdLessons = withStyles(styles)(ViewdLessons);
export default ViewdLessons;
