import React from 'react';
import { Link } from 'react-router-dom';

function BannerPreSignup() {
  return (
    <div className="alert alert-warning alert-dismissible fixed-top text-center" role="alert">
      <strong>Experimente agora!</strong> Acesse nossas 6 aulas gratuitas e descubra como podemos ajudá-lo a desenvolver suas habilidades. Para ter acesso a mais de 200 aulas,{' '}
      <Link to="/pre-signup/complete" className="alert-link font-weight-bold">
        complete seu cadastro agora mesmo! <i className="fa fa-arrow-right"></i>
      </Link>
      <br />
      <a href="https://capacitampe.com.br/" target="_blank" rel="noopener noreferrer" className="alert-link">
        Saiba mais sobre nossos serviços e consultores
      </a>
    </div>
  );
}

export default BannerPreSignup;
