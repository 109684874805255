import styled from 'styled-components';

export const Container = styled.div`
  .title {
    font-family: Gotham Rounded Book;
    font-size: 45pt;
    color: #f5a623;
  }
  .progress {
    color: #17549b;
  }
`;
