import api from 'axios';

export interface CepResponse {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
}

export const findCep = (cep: string) =>
  api.get<CepResponse>(`https://brasilapi.com.br/api/cep/v1/${cep}`);
