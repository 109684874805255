import React, { Component } from "react";
import { withStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { MAIN_SCREEN, THEME_DETAILS } from "../../../constants/routes";
import MainHeader from "../MainHeader";
import { db, auth } from "../../../firebase";
import VOLTAR from "../Images/Voltar.png";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Loading from "../Loading";
import _ from "lodash";
import logoImg from "../Images/Aplicaçãologo.png";
import $ from "jquery";
import moment from "moment";

class AdditionalLessons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: auth.getAuthUser(),
			themeDataReturn: [],
			themeDataReturnType: [],
			themeDataReturnBKP: [],
			classDataReturn: [],
			classDataReturnBKP: [],
			isLoading: true,
			themeUserPurchase: [],
			userData: {},
		};
	}

	async componentDidMount() {
        //++++++++++++++FUNÇÃO PARA SER USADA DEPOIS DE IMPLEMENTAR E-COMMERCE+++++++++++
		const { currentUser } = this.state;
		let user = await db.refNode(`UsersBB/${currentUser.uid}`).once("value")
		user = user.val()
    this.setState({userData: user})
		// const themes = await db.oncePremiumUserAndTheme(currentUser.uid);
		let classes = await db.refNode(`Classes/`).orderByChild("classType").equalTo('theme').once("value")
		classes = classes.val()
		let themes = [];
		_.forEach(classes, _class =>  {
			if (_class.themeId && _class.themeId != '') themes.push(_class.themeId)
		});
		themes = _.uniq(themes)
		var resultAll = [];
		await _.forEach(themes, async themeId => {
			await db.onceGetThemeById(themeId).then(result => {
				const _theme = result.val()
				if (_theme.themeType == user.type) {
					resultAll.push(_theme);
				}
			});
		});

		const themesData = await _.each(resultAll,result =>{
			// console.log(result)
			return result
		})

		// console.log("---------------------------->",themesData)

		this.setState({ themeUserPurchase: themesData });
		this.setState({ themeDataReturn: themesData, themeDataReturnBKP: themesData });
		// // console.log(">>>================================>",themesData)
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

		// db.onceGetTheme()
		// 	.then(themeReturn => {
		// 		if (themeReturn.val() !== null) {
		// // console.log(">>>================================>",themeReturn.val())

		// 			this.setState({ themeDataReturn: themeReturn.val(), themeDataReturnBKP: themeReturn.val() });
		// 		}
		// 	})
		// 	.catch(error => {
		// 		// console.log("onceGetTheme error:", error);
		// 	});

		db.onceGetClass()
			.then(classReturn => {
				if (classReturn.val() !== null) {
					this.setState({ classDataReturn: classReturn.val(), classDataReturnBKP: classReturn.val() });
				}
			})
			.catch(error => {
				// console.log("onceGetClass error:", error);
			});

		this.getUserType();
	}

	handleSearchClasses = () => {
		this.setState({ loading: true });
		this.setState({ classReturnData: [] });
		const searchData = {
			themeType: this.state.selectedThemeType,
			themeId: this.state.selectedThemeId,
			classId: this.state.selectedClassId,
		};
		$.ajax({
			type: "GET",
			url: "https://us-central1-capacitampe-b1bf8.cloudfunctions.net/handleFilterClasses",
			data: { ...searchData },
			dataType: "JSON",
			success: classData => {
				//// console.log("Sucesso functions:", classData);
				if (classData.returnDataClasses !== null) {
					this.setState({ classReturnData: classData, loading: false });
				} else {
					this.setState({ classReturnData: [], loading: false });
				}
			},
			error: () => {
				// console.log("error functions");
			},
		});
	};

	handleChangeTheme(e) {
		this.setState({ selectedThemeId: e.target.value });
	}

	handleChangeClass(e) {
		this.setState({ selectedClassId: e.target.value });
	}

	getClassByTheme(e) {
		const data = e.target.value;
		if (data == "") {
			this.setState({ classDataReturn: this.state.classDataReturnBKP });
		} else {
			const classesReturned = _.filter(this.state.classDataReturnBKP, classes => {
				if (classes.themeId === data) {
					return classes;
				}
			});
			this.setState({ classDataReturn: classesReturned });
		}
	}
	async getPurchaseThemes() {}
	getUserType() {
		let userUid = auth.getAuthUser().uid;
		db.refNode(`UsersBB/${userUid}/type`)
			.once("value")
			.then(userType => {
				if (userType.val()) {
					this.setState({ userType: userType.val() }, () => {
						this.getThemeByType();
					});
				}
			})
			.catch(error => {
				// console.log("Error", error);
			});
	}

	getThemeByType() {
		const type = this.state.userType;
		if (!type) {
			this.setState({ themeDataReturn: [] });
			this.setState({ themeDataReturnType: [] });
		} else {
			// // console.log('this.state.themeDataReturnBKP', this.state.themeDataReturnBKP);
			const themesReturned = _.filter(this.state.themeDataReturnBKP, theme => {

					return theme;

			});
			this.setState({ themeDataReturn: _.orderBy(themesReturned, "themeName", "asc") });
			this.setState({ themeDataReturnType: _.orderBy(themesReturned, "themeName", "asc") });

		}
		this.setState({ isLoading: false });
	}

	handleSearchThemes(e) {
		const text = e.target.value;
		if (text === "") {
			this.setState({ themeDataReturn: this.state.themeDataReturnType, themeSearch: text });
		} else {
			const themesReturned = _.filter(this.state.themeDataReturn, theme => {
				if (_.includes(_.lowerCase(theme.themeName), _.lowerCase(text))) {
					return theme;
				}
			});
			this.setState({ themeDataReturn: themesReturned, themeSearch: text });
		}
	}

	render() {
		const { classes } = this.props;

		return this.state.isLoading ? (
			<Loading />
		) : (
			<div className="container-fluid">
				<div className="background">
					<MainHeader  logado={this.state.currentUser ? true : false} />
					<div className="col-xs-12 col-md-10 col-md-offset-1">
						<div className="col-md-8">
							<Link to={MAIN_SCREEN}>
								<div className="col-lg-1 col-md-2 col-xs-3">
									<img
										className="img-responsive center-block image-back"
										src={VOLTAR}
									/>
								</div>
								<u className={classes.link}>Voltar</u>
							</Link>
						</div>
					</div>
					<div
						className="col-md-10 col-md-offset-1 col-xs-12"
						style={{ textAlign: "center", marginTop: "2%" }}>
						<span className="responsive-title">VÍDEO AULAS COMPLEMENTARES</span>
						<br />
					</div>
					<div
						className="col-xs-12 col-md-10 col-md-offset-1"
						style={{ marginTop: "3%" }}>
						<div className="row">
							<div className="col-md-5 col-xs-12" style={{ marginLeft: "3%" }}>
								<input
									id="search"
									value={this.state.themeSearch}
									name="themeSearch"
									onChange={e => this.handleSearchThemes(e)}
									placeholder="Pesquisar por tema"
									type="search"
									className="form-search"
								/>
								<div id="lupa" />
							</div>
						</div>
					</div>
					<div
						className="col-xs-12 col-md-10 col-md-offset-1"
						style={{ marginBottom: "3%" }}>
						{this.state.themeDataReturn != "" ? (
							_.map(this.state.themeDataReturn.filter(theme => !["-NQfelGSko0HV6YmIy2R", "-NQfeg7G8fVz3Zhiq4-8"].includes(theme.uid)), theme => {
								// // console.log("Este é o tema", theme);
                                // if (this.state.userType && theme.themeType) {
								// 	if (this.state.userType != theme.themeType) {
								// 		// console.log('Skipping the alien: ', theme);
								// 		return;
								// 	}
                                // }
								return (
									<div className="col-md-4 col-xs-12 card-div">
										<Link
											to={{
												pathname: THEME_DETAILS,
												state: { theme: theme },
											}}>
											<Card className="card card-radius-additional-lesson">
												<CardMedia
													className="card-addtional-lessons"
													image={
														theme.themeImgUrl
															? theme.themeImgUrl
															: logoImg
													}
												/>
												<CardContent>
													<div style={{ display: "table" }}>
														<div
															style={{
																display: "tableCell",
																verticalAlign: "bottom",
															}}>
															<div>
																<span className="card-title">
																	{theme.themeName.length < 50
																		? theme.themeName.toUpperCase()
																		: theme.themeName
																				.toUpperCase()
																				.substring(0, 50) +
																		  "..."}
																</span>
															</div>
														</div>
													</div>
													<hr
														style={{
															borderColor: "#979797",
															borderWidth: "1px",
														}}
													/>
													<div className="card-views">
														<i className="fa fa-eye" />{" "}
														{theme.views ? theme.views : 0}
													</div>
												</CardContent>
											</Card>
										</Link>
									</div>
								);
							})
						) : (
							<div className="col-xs-12 text-center" style={{ marginTop: 100 }}>
								<span>
									{" "}
									<div id="frown" />{" "}
									<span className="margin-text-oops">
										Oops. Nenhum tema foi comprado.
									</span>
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
const styles = {
	root: {
		flex: 1,
	},
	body: {
		fonWeight: 100,
	},
	text: {
		fontFamily: "Gotham Rounded Medium",
		fontSize: "25pt",
		color: "#505050",
	},
	link: {
		fontFamily: "Gotham Rounded Book",
		fontSize: "20pt",
		color: "black",
	},
	media: {
		height: 200,
		backgroundSize: "contain",
	},
};

// const authCondition = authUser => !!authUser;
// AdditionalLessons = withAuthorization(authCondition)(AdditionalLessons);
AdditionalLessons = withStyles(styles)(AdditionalLessons);
export default AdditionalLessons;
