import React from "react";
import logoImg from "../Images/logo.png";

import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { auth, db } from "../../../firebase";
import * as routes from "../../../constants/routes";
import $ from "jquery";
import swal from 'sweetalert';
import * as mask from "../Common/mascaras";
import _ from 'lodash';
import Loading from "../Loading";
import qs from 'qs'
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2'
import Modal from '../Modal'



const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  result: null,
  showModal: true,
  isLoading: true,
  phone: '',
  proofOfSale: '',
  userBought: false,
  promotionalSignup: false,
  newModal: false
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.hideNewModal = this.hideNewModal.bind(this)

    this.state = {
      ...INITIAL_STATE,
      cookies: new Cookies(),
      newModalData:{}

    };

  }

  componentWillMount() {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.setState({ email: query.email, activationKey: query.activationKey })
    if (auth.getAuthUser()) {
      this.props.history.push(routes.MAIN_SCREEN);
    }
  }

  componentDidMount() {
    db.refNode('FreeSignupConfigBB').once('value', (snapshot) => {
      const signupConfig = snapshot.val()
      let promotionalSignup = true;
      if (!signupConfig) {
        promotionalSignup = true;
      } else if (signupConfig.activated) {
        if (signupConfig.expirationByDate) {
          const now = moment(moment().format('DD/MM/YY'), 'DD/MM/YY');
          const expirationDate = moment(signupConfig.expirationDate, 'DD/MM/YY')
          if (now > expirationDate) {
            promotionalSignup = false;
          }
        }

        if (signupConfig.registerAmount >= signupConfig.registerLimit) {
          promotionalSignup = false;
        }

        this.setState({ promotionalSignup: promotionalSignup });
      }
    }).then(() => {
      this.setState({
        isLoading: false,
        userBought: false
      });
      if (this.state.email && this.state.activationKey) {
        this.claimPurchaseModelSubmit()
      }
    })
  }

  // activationKey() {
  //     const activationKey = this.state.activationKey
  //     db.refNode(`Purchases/`)
  //         .orderByChild('activationKey')
  //         .equalTo(activationKey)
  //         .limitToLast(1)
  //         .once('value')
  //         .then(snapshot => {
  //             const purchases = snapshot.val();
  //             let purchase = undefined;
  //             if (purchases) {
  //                 purchase = {
  //                     ...purchases[Object.keys(purchases)],
  //                     key: Object.keys(purchases)
  //                 }
  //             }
  //             if (purchase && purchase.hotmart.email == this.state.email) {


  //                 if (purchase.userUid) {
  //                     swal({
  //                         text: 'Essa compra já foi finalizada! Caso você não tenha feito a finalização ou esteja com problemas, entre em contato em contato@capacitampe.com.br',
  //                         icon: "error",
  //                         buttons: {
  //                             cancel: false,
  //                             confirm: true,
  //                         },
  //                         dangerMode: true,
  //                     });
  //                     return;
  //                 }

  //                 // Add local storage info, so the main screen recognizes the
  //                 // user purchase
  //                 localStorage.setItem('purchaseKey', purchase.key);

  //                 var userBought = 'CHOOSE';
  //                 localStorage.setItem('pkg', pkg);

  //                 swal({
  //                     text: 'Compra recuperada! Na próxima página, termine seu cadastro.',
  //                     icon: "success",
  //                     buttons: {
  //                         cancel: false,
  //                         confirm: true,
  //                     },
  //                     dangerMode: false,
  //                 }).then(() => {
  //                     this.props.history.push(routes.START + '?p=' + activationKey);
  //                     $("#claimPurchaseModalClose").click();
  //                 });

  //             } else {
  //                 swal({
  //                     text: 'Compra não encontrada. Por favor, confira os dados ou entre em contato em contato@capacitampe.com.br',
  //                     icon: "error",
  //                     buttons: {
  //                         cancel: false,
  //                         confirm: true,
  //                     },
  //                     dangerMode: true,
  //                 });
  //             }
  //         });
  // }

  Forget = event => {
    const { passwordEmail } = this.state;
    auth
      .doPasswordReset(passwordEmail)
      .then(() => {
        swal({
          text: "Enviamos um email com as instruções para recuperar a sua senha.",
          icon: "success",
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: true,
        });
        $("#passwordModalClose").click();
      })
      .catch(() => {
        swal({
          text: "Ocorreu um erro ao enviar o email, tente novamente!",
          icon: "error",
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: true,
        });
        $("#passwordModalClose").click();
      });
    event.preventDefault();
  };

  login(loginUser) {
    const { password } = this.state;
    const { history } = this.props;
    this.setState({ error: null });
    auth
      .doSignInWithEmailAndPassword(loginUser.email, password)
      .then((snapshot) => {
        db.onceGetUsers(snapshot.uid).then((user) => {
          let authUser = user.val();
          if (user.val() != null) {
            db.refNode(`UsersBB/${authUser.uid}`).update({
              lastLogin: moment().valueOf(),
            });
            this.state.cookies.set('userId', authUser.uid);
            this.state.cookies.set('userType', authUser.type);
            // localStorage.setItem("userId", authUser.uid);
            // localStorage.setItem("userType", authUser.type);
            history.push(routes.MAIN_SCREEN);
          }
          else {
            alert("Acesso exclusivo para usuários")
          }
        });
      })
      .catch(() => {
        swal({
          text: "Usuário ou senha incorretos!",
          icon: "error",
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: true,
        });
      });
  }

  Submit = (event) => {
    this.setState({ showModal: false });
    $("#modalClose").click();
    const email = this.state.email;
    const pass = this.state.password;
    const isInvalid = pass === "" || email === "" || !email || !pass;
    if (!isInvalid) {
      db.refNode(`UsersBB/`).orderByChild("email").equalTo(email).once("value").then(loginUser => {
        if (loginUser.val()) {
          let _loginUser = _.values(loginUser.val())[0];
          // const expiration_date = _loginUser.expiration_date;
          // if (!expiration_date || moment() <= moment(expiration_date)) {
          this.login(_loginUser);
          // } else {
          //     swal({
          //         text: "Tempo de acesso expirado!",
          //         icon: "error",
          //         buttons: {
          //             cancel: false,
          //             confirm: true,
          //         },
          //         dangerMode: true,
          //     });
          // }
        } else {
          swal({
            text: "Email ou senha incorretos!",
            icon: "error",
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
        }
      }).catch(() => {
        swal({
          text: "Ocorreu algum erro!",
          icon: "error",
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: true,
        });
      });
    } else {
      swal({
        text: "Por favor preencha todos os campos!",
        icon: "error",
        buttons: {
          cancel: false,
          confirm: true,
        },
        dangerMode: true,
      });
    }

    event.preventDefault();
  };

  claimPurchaseModelSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const activationKey = this.state.activationKey
    db.refNode(`Purchases/`)
      .orderByChild('activationKey')
      .equalTo(activationKey)
      .limitToLast(1)
      .once('value')
      .then(snapshot => {
        const purchases = snapshot.val();
        let purchase = undefined;
        if (purchases) {
          purchase = {
            ...purchases[Object.keys(purchases)],
            key: Object.keys(purchases)
          }
        }
        if (purchase && purchase.hotmart.email == this.state.email) {

          if (purchase.userUid) {
            this.setState({ userBought: false });
            swal({
              text: 'Essa compra já foi finalizada! Caso você não tenha feito a finalização ou esteja com problemas, entre em contato em contato@capacitampe.com.br',
              icon: "error",
              buttons: {
                cancel: false,
                confirm: true,
              },
              dangerMode: true,
            });
            return;
          }

          // Add local storage info, so the main screen recognizes the
          // user purchase
          // localStorage.setItem('purchaseKey', purchase.key);

          // var userBought = 'CHOOSE';
          // localStorage.setItem('pkg', pkg);

          swal({
            text: 'Compra recuperada! Na próxima página, termine seu cadastro.',
            icon: "success",
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: false,
          }).then(() => {
            // this.props.history.push(routes.START + '?p=' + activationKey);
            this.setState({ userBought: true })
            $("#claimPurchaseModalClose").click();
          });

        } else {
          this.setState({ userBought: false });
          swal({
            text: 'Compra não encontrada. Por favor, confira os dados ou entre em contato em contato@capacitampe.com.br',
            icon: "error",
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
        }
      }).catch(() => {
        this.setState({ userBought: false });
        swal({
          text: "Ocorreu algum erro!",
          icon: "error",
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: true,
        });
      });
  }

  async verify() {
    await Swal.fire({
      title: 'Olá! Infelizmente atingimos o limite de cadastros liberados :(',
      text: 'Gostaria de ser avisado quando liberarmos mais cadastros?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Sim, gostaria!',
      cancelButtonText: 'Não, obrigado.',
      backdrop: 'rgba(0,0,0,0.8)',
      allowOutsideClick: false,
      width: '50em',
    }).then((result) => {
      if (result.isConfirmed)
        this.setState({ newModal: true })
    })
  }
  promotionalSignupSubmit() {
    if (this.state.promotionalSignup)
      this.props.history.push(routes.SIGN_UP);
    else {
      this.verify()
    }
  }

  hideNewModal() {
    this.setState({ newModal: false })
  }

  render() {
    const { classes } = this.props;
    return (
      this.state.isLoading ?
        <Loading />
        :
            <div className="container-fluid">
          <div className="background">
            <div className="row">
              <div className="col-md-12">
                <img
                  id="profile-img"
                  className="img-responsive center-block"
                  src={logoImg}
                  alt="Capacita Admin"
                  width="365vh"

                />
              </div>
              <div style={{ marginTop: -10, height: 26 }} className="col-md-10 col-md-offset-1">
                <hr style={{ borderColor: '#D6D8D8', borderWidth: '2px' }} />
              </div>
              <div className="col-md-12" style={{ textAlign: 'center', marginTop: 0 }}>
                <span className="resposive-title-dashboard">Boas vindas!</span><br />
              </div>

              <div className="col-md-12 col-xs-12" style={{ marginTop: "1%", marginBottom: "1%", textAlign: 'center' }}>
                <span className={classes.text2}>Já tem cadastro?</span><br /><br />
                <u data-toggle="modal" data-target="#loginModal" id="openLogin" className={classes.link} onClick={() => this.setState({ showModal: true })}>CLIQUE AQUI PARA ENTRAR</u>
              </div>
              <div className="col-md-12 col-xs-12" style={{ marginTop: "1%", marginBottom: "1%", textAlign: 'center' }}>
                <span className={classes.text2}>Faça seu cadastro gratuitamente!</span><br /><br />
                <u id="openSignupPromotional" className={classes.link} onClick={() => this.promotionalSignupSubmit()}>CLIQUE AQUI PARA CADASTRAR</u>
              </div>
              <div className="col-md-12 col-xs-12" style={{ marginTop: "1%", marginBottom: "1%", textAlign: 'center' }}>
                <span className={classes.text2}>Ainda não nos conhece?</span><br /><br />
                <u id="openSignupPromotional" className={classes.link}>
                  <a href="https://capacitampe.com.br/" target="_blank">
                    CLIQUE AQUI PARA SABER MAIS
                                </a>
                </u>
              </div>


              {this.state.newModal && <div style={{position: 'absolute' ,backgroundColor: 'rgba(0,0,0,0.8)', width: '100%', height: '100%'}}>
                <Modal hideNewModal={this.hideNewModal} />
              </div>
              }{/* {!userBought && <div className="col-md-12 col-xs-12" style={{ marginTop: "1%", marginBottom: "1%", textAlign: 'center' }}>
                            <span className={classes.text3}>Realizou uma compra, mas não fez seu cadastro?</span><br /><br />
                            <u data-toggle="modal" data-target="#claimPurchaseModal" id="openClaimPurchase" className={classes.link2}>CLIQUE AQUI</u>
                        </div>
                        } */}
              {this.state.showModal &&
                <div id="loginModal" className="modal fade" show="1" tabindex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button id="modalClose" type="button" className="close" data-dismiss="modal" aria-label="Close"
                          onClick={() => this.setState({ showModal: false })}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form id="form-login">
                          <div className="form-group">
                            <div className="col-md-12">
                              <label for="login-user" className="login-label">Email</label>
                            </div>
                            <div className="col-md-12">
                              <input type="text" name="email" id="login-user" className="form-control placeholder" placeholder=" Ex: seunome@gmail.com"
                                value={this.state.email ? this.state.email : ""}
                                onChange={(e) => {
                                  let input = e.target;
                                  mask.maskEMAIL(input.value).then(masked => {
                                    this.setState({
                                      email: masked
                                    })
                                  })
                                }} />
                              <br />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-12">
                              <label for="password-user" className="login-label">Senha</label>
                            </div>
                            <div className="col-md-12">
                              <input type="password" id="password-user" className="form-control placeholder" placeholder=" Ex: 99***********"
                                onChange={(event) =>
                                  this.setState({
                                    password: event.target.value,
                                  })
                                } /><br />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <span data-dismiss="modal" data-toggle="modal" data-target="#password-modal" id="password-recovery" className="clickable"
                              onClick={() => this.setState({ showModal: false })}>Esqueceu a senha?</span>
                          </div>
                          <div className="row">
                            <div className="col-xs-12 col-xs-offset-2">
                              <button id="btn-entrar" type="submit" className="btn btn-default btn-lg clickable" onClick={this.Submit}>ENTRAR</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              }

              <div id="claimPurchaseModal" className="modal fade" show="1" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button id="claimPurchaseModalClose" type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => this.setState({ showModal: false })}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="col-md-12 row">
                        <p>Se você fez uma compra, mas não terminou seu cadastro, basta informar os dados que recebeu por Email nos campos abaixo:</p>
                      </div>
                      <form onSubmit={this.claimPurchaseModelSubmit}>
                        <div className="col-md-8">
                          <div className="form-group">
                            <label for="email" className="login-label">Email</label>
                            <input type="text" name="email" id="email" maxlength="15" className="card-form form-control input-placeholder-profile" placeholder="Ex: email@example.com"
                              value={this.state.email}
                              onChange={(e) => {
                                let input = e.target;
                                mask.maskEMAIL(input.value).then(masked => {
                                  this.setState({
                                    email: masked,
                                  });
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="form-group">
                            <label for="activationKey" className="login-label">Chave de ativação</label>
                            <input type="text" name="activationKey" className="card-form form-control input-placeholder-profile"
                              placeholder="HP1121336654889"
                              onChange={(e) =>
                                this.setState({
                                  activationKey: e.target.value,
                                })
                              }
                              value={this.state.activationKey}
                            />
                            <small className="form-text">O comprovante pode ser encontrado no email enviado quando sua compra foi aprovada.</small>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-xs-12 col-xs-offset-2">
                              <button id="btn-entrar" type="submit" className="btn btn-default btn-lg clickable">ENTRAR</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div id="password-modal" className="modal fade" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button id="passwordModalClose" type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form id="password-login">
                        <div className="form-group">
                          <div className="col-md-12">
                            <label for="password-forget" className="login-label">Email para recuperação de senha</label>
                          </div>
                          <div className="col-md-12">
                            <input type="text" id="password-forget" className="form-control placeholder" placeholder=" Ex: email@email.com"
                              onChange={(event) =>
                                this.setState({
                                  passwordEmail: event.target.value,
                                })
                              } />
                            <br />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12 col-xs-offset-2">
                            <button id="btn-enviar" type="submit" className="btn btn-default btn-lg clickable" onClick={this.Forget}>ENVIAR</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const styles = {
  root: {
    flex: 1
  },
  body: {
    fonWeight: 100
  },
  text: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "200%",
    color: '#505050'
  },
  text2: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "230%",
    color: '#505050'
  },
  text3: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "220%",
    color: '#505050'
  },
  link: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "220%",
    color: '#4A90E2',
    cursor: 'pointer'
  },
  link2: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "190%",
    color: '#4A90E2',
    cursor: 'pointer'
  }
};

export default withStyles(styles)(Dashboard);
