import { ActionType } from 'typesafe-actions';

/*
  ACTION TYPES
*/
export enum ReduxTypes {
  CHANGE = '@@redux-form/CHANGE',
}

export type ReduxAction = ActionType<typeof import('./actions')>;
