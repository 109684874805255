import { validateBr } from 'js-brasil';

export const required = (value: any) =>
  value || typeof value === 'number' ? undefined : 'Obrigatório!';

export const maxLength = (max: number) => (value: any) =>
  value && value.length > max
    ? `Deve ter ${max} caracteres ou menos!`
    : undefined;

export const minLength = (min: number) => (value: any) =>
  value && value.length < min
    ? `Deve ter ${min} caracteres ou mais!`
    : undefined;

export const shouldBeEqual = (to: string, name: string) => (
  value: any,
  allValues: any,
) => (allValues[name] !== value ? `${to} não são iguais!` : undefined);

export const number = (value: any) =>
  value && Number.isNaN(value) ? 'Tem que ser um número!' : undefined;

export const minValue = (min: number) => (value: any) =>
  value && !Number.isNaN(value) && Number(value) < min
    ? `Tem que ser maior ou igual a ${min}!`
    : undefined;

export const maxValue = (max: number) => (value: any) =>
  value && !Number.isNaN(value) && Number(value) > max
    ? `Tem que ser maior ou igual a ${max}!`
    : undefined;

export const email = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Email inválido!'
    : undefined;

export const alphaNumeric = (value: any) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Tem que ser alpha numérico'
    : undefined;

export const phoneNumber = (value: any) =>
  value &&
  !/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/i.test(value)
    ? 'Número inválido!'
    : undefined;

export const validCpf = (value?: string) =>
  validateBr.cpf(value) ? undefined : 'CPF inválido!';

export const validCnpj = (value?: string) =>
  validateBr.cnpj(value) ? undefined : 'CNPJ inválido!';

export const validCep = (value?: string) =>
  validateBr.cep(value) ? undefined : 'CEP inválido!';
