import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import rootStore from './store';
import Routes from './routes';
import Loading from './components/Loading';

const App: React.FC = () => (
  <Provider store={rootStore.store}>
    <PersistGate loading={<Loading />} persistor={rootStore.persistor}>
      <BrowserRouter>
        <Routes />
        {/* <GlobalStyle /> */}
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
