import { auth } from '../firebase/firebase';

export interface CredentialsDTO {
  email: string;
  password: string;
}

// Sign Up
export const createAccount = ({ email, password }: CredentialsDTO) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const signIn = ({ email, password }: CredentialsDTO) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const signOut = () => auth.signOut();

// Password Reset
export const resetPassword = (email: string) =>
  auth.sendPasswordResetEmail(email);

// Password Change
export const passwordUpdate = (password: string) =>
  auth.currentUser.updatePassword(password);

// Get Auth User
export const getAuthUser = () => auth.currentUser;

// Boolean to check if user is logged
export const userLogged = () => !!auth.currentUser;
