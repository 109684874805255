import React, { InputHTMLAttributes, useCallback } from 'react';
import { BaseFieldProps, Field } from 'redux-form';
import { Container } from './styles';

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'> {
  name: string;
  id: string;
  values: string[];
  label?: string;
}

const Input: React.FC<Props & BaseFieldProps> = ({
  className,
  label,
  id,
  values,
  ...rest
}) => {
  const renderField: BaseFieldProps = useCallback(
    ({ input, meta: { dirty, error, warning }, ...rest }) => (
      <>
        <input {...input} {...rest} />
        {dirty &&
          ((error && <div className="alert alert-danger">{error}</div>) ||
            (warning && <div className="alert alert-warning">{warning}</div>))}
      </>
    ),
    [],
  );

  return (
    <Container className={className}>
      <div className="form-row">
        {label && (
          <div className="form-group col-md-6">
            <span className="register-label">{label}</span>
          </div>
        )}
        {values.map((value, index) => (
          <div
            key={`${id}-${index}`}
            className={`form-group col-md-2 ${!index && 'col-md-offset-1'}`}
          >
            <div className="form-check">
              <label className="label-check">
                <Field
                  {...rest}
                  component={renderField}
                  type="radio"
                  id={`${id}-${index}`}
                  value={value}
                />
                &nbsp;
                <span className="label-text">{value.toUpperCase()}</span>
              </label>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Input;
