import { applyMiddleware, createStore, Middleware, StoreEnhancer } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import localForage from 'localforage';

import { persistReducer, persistStore } from 'redux-persist';
import { FormStateMap } from 'redux-form';
import rootReducer from './modules/rootReducer';
import rootSagas from './modules/rootSaga';
import { SignUpState } from './modules/SignUp/types';

export interface AppState {
  form: FormStateMap;
  signUp: SignUpState;
}

const enhancers: StoreEnhancer[] = [];

const sagaMiddleware = createSagaMiddleware();
const middleware: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    level: 'info',
    collapsed: true,
  });
  middleware.push(logger);
}

const composedEnhancers: StoreEnhancer = composeWithDevTools(
  applyMiddleware(...middleware),
  ...enhancers,
);

const persistConfig = {
  key: 'root',
  storage: localForage,
  // whitelist: [],
  blacklist: ['form', 'signUp'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = {
  ...createStore(persistedReducer, composedEnhancers),
  runSaga: sagaMiddleware.run,
};

const persistor = persistStore(store);

store.runSaga(rootSagas);

export default { store, persistor };
