import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import logoImg from '../../assets/images/logo.png';
import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <Container className="container-fluid">
      <div className="background">
        <div className="row text-center">
          <div className="col-md-12 col-xs-12">
            <img src={logoImg} alt="Capacita Admin" className="loading-img" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-12 col-xs-12">
            <span className="text-loading">Carregando...</span>
          </div>
        </div>
        <div className="col-md-12 col-xs-12 text-center mobile-div">
          <CircularProgress size={180} className="progress" />
        </div>
      </div>
    </Container>
  );
};

export default Loading;
