import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { db } from '../../../../firebase/firebase';
import { getAuthUser } from '../../../../services/authService';
import { Container } from './styles';

interface Props {
  link: string;
  getVideoId(url: any): string;
  watchedTutorial(): void;
}

const TutorialComponent: React.FC<Props> = ({
  watchedTutorial,
  link,
  getVideoId,
}) => {
  return (
    <Container className="container-fluid">
      <div className="background">
        <div className="row">
          <div
            className="col-md-12"
            style={{ textAlign: 'center', marginTop: '6%' }}
          >
            <span className="title">Tutorial</span>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-8 col-md-offset-2"
            style={{ marginBottom: '6%' }}
          >
            <div
              id="div-main-video"
              className="embed-responsive embed-responsive-16by9 video-tutorial"
            >
              {link && (
                <Vimeo
                  video={getVideoId(link)}
                  autoplay
                  onEnd={() => {
                    const userUid = getAuthUser().uid;
                    db.ref(`UsersBB/${userUid}/`).update({ tutorial: true });
                  }}
                />
              )}
              {/* <YouTube
                  id="main-video"
                  videoId={this.getVideoId(link)}
                  opts={opts}
              /> */}
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '8%' }}>
          <div className="col-md-8 col-md-offset-2">
            <button
              onClick={watchedTutorial}
              id="btn-continuar"
              type="submit"
              className="btn btn-default btn-lg col-lg-4 col-md-4 col-xs-12 cursor-pointer"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TutorialComponent;
