import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as routes from '../../../../constants/routes';
import Loading from '../../../../deprecated/components/Loading';
import TutorialComponent from './component';

const MEI_VIDEO = 'https://vimeo.com/340061573';
const MPE_VIDEO = 'https://vimeo.com/340067256';
const EPP_VIDEO = 'https://vimeo.com/340067256';
const PR_VIDEO = 'https://vimeo.com/340061573';
const PF_VIDEO = 'https://vimeo.com/340061573';

interface Props {
  type: string;
  watchedTutorial(): void;
}

const TutorialContainer: React.FC<Props> = ({ type, watchedTutorial }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState('');

  useEffect(() => {
    if (type) {
      switch (type) {
        case 'MEI':
          setLink(MEI_VIDEO);
          break;
        case 'MPE':
          setLink(MPE_VIDEO);
          break;
        case 'EPP':
          setLink(EPP_VIDEO);
          break;
        case 'PR':
          setLink(PR_VIDEO);
          break;
        default:
          setLink(PF_VIDEO);
      }
    } else {
      history.push(routes.MAIN_SCREEN);
    }
    setLoading(false);
  }, [history, type]);

  const getVideoId = useCallback(
    url =>
      String(url || '').replace(
        /(https?:)?(\/\/)?(player\.)?vimeo\.com(\/video)?\//gi,
        '',
      ),
    [],
  );

  return loading ? (
    <Loading />
  ) : (
    <TutorialComponent
      watchedTutorial={watchedTutorial}
      getVideoId={getVideoId}
      link={link}
    />
  );
};

export default TutorialContainer;
