import React, { useCallback, useState } from 'react';
import {
  InjectedFormProps,
  reduxForm,
  setSubmitFailed,
  setSubmitSucceeded,
} from 'redux-form';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/store';
import { SignUpState } from 'src/store/modules/SignUp/types';
import Loading from 'src/components/Loading';
import {
  createUser,
  getUserIdByEmail,
  userAlredyExist,
} from 'src/services/userService';
import swal from 'sweetalert';
import { createAccount } from 'src/services/authService';
import {
  startLoadingAction,
  stopLoadingAction,
} from 'src/store/modules/SignUp/actions';
import { useHistory } from 'react-router-dom';
import { SIGN_IN } from 'src/constants/routes';
import PreSignUpComponent from './component';
import Tutorial from './components/Tutorial';
import SelectType from './components/SelectType';
import getTypeName from './utils/typesName';

const PreSignUpContainer: React.FC<
  InjectedFormProps<{ registerDate: string }>
> = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading } = useSelector<AppState, SignUpState>(state => state.signUp);

  const [type, setType] = useState<string | null>(null);
  const [tutorialWatched, setTutorialWatched] = useState(false);

  const watchedTutorial = useCallback(() => {
    setTutorialWatched(true);
  }, []);

  const submit = useCallback(
    async (data: Record<string, any>) => {
      console.log('submit', data);
      dispatch(startLoadingAction());
      const userExist = await userAlredyExist(
        type.includes('PF') ? data.cpf : data.cnpj,
        data.email,
      );
      console.log('userExist', userExist);

      if (!userExist) {
        try {
          const { email, password } = data;
          let uid: string;
          let emailAlreadyExist = false;
          try {
            const response = await createAccount({ email, password });
            uid = response.uid;
          } catch (error) {
            if ((error as any).code === 'auth/email-already-in-use') {
              const shouldContinue = await swal({
                title:
                  'Email já cadastrado em um dos nossos parceiros: [parceiro].capacitampe.com.br',
                text:
                  'A sua senha será a mesma já cadastrada anteriormente. Deseja continuar?',
                buttons: ['Não', 'Sim'],
                dangerMode: true,
              });
              if (shouldContinue) {
                uid = await getUserIdByEmail(email);
                if (!uid) {
                  throw error;
                }
                emailAlreadyExist = true;
              } else {
                dispatch(stopLoadingAction());
                dispatch(setSubmitFailed('signUp'));
                return;
              }
            } else {
              throw error;
            }
          }

          const dataWithoutPassword = { ...data };
          delete dataWithoutPassword.password;
          delete dataWithoutPassword.passwordConfirm;

          await createUser(
            {
              ...dataWithoutPassword,
              accountType: getTypeName(type),
              type: type.includes('PF') ? 'PF' : 'PJ',
              preSignup: true,
            },
            uid,
          );
          dispatch(stopLoadingAction());
          if (emailAlreadyExist) {
            await swal({
              title: 'Cadastro realizado com sucesso!',
              text: 'Você já pode fazer login na plataforma.',
              icon: 'success',
              buttons: {
                cancel: false,
                confirm: true,
              },
              dangerMode: false,
            });
            history.push(SIGN_IN);
          } else {
            await swal({
              title: 'Cadastro realizado com sucesso!',
              text: 'Você já pode fazer login na plataforma.',
              icon: 'success',
              buttons: {
                cancel: false,
                confirm: true,
              },
              dangerMode: false,
            });
          }
          dispatch(setSubmitSucceeded('signUp'));
          history.push(SIGN_IN);
        } catch (err) {
          dispatch(stopLoadingAction());
          await swal({
            text:
              'Desculpe, ocorreu um erro ao cadastrar sua conta! Tente novamente em alguns minutos.',
            icon: 'error',
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
          dispatch(setSubmitFailed('signUp'));
          throw err;
        }
      } else {
        dispatch(stopLoadingAction());
        if (userExist === 'cpf') {
          await swal({
            text: `${
              type.includes('PF') ? 'CPF' : 'CNPJ'
            } já cadastrado! Por favor, escolha outro!`,
            icon: 'error',
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
        } else {
          await swal({
            text: 'Email já cadastrado! Por favor, escolha outro!',
            icon: 'error',
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
        }
        dispatch(setSubmitFailed('signUp'));
      }
    },
    [dispatch, history, type],
  );

  return !type ? (
    <SelectType selectType={setType} />
  ) : !tutorialWatched ? (
    <Tutorial type={type} watchedTutorial={watchedTutorial} />
  ) : (
    <>
      {loading && <Loading />}
      <div style={{ display: loading ? 'none' : 'block' }}>
        <PreSignUpComponent {...props} type={type} submit={submit} />
      </div>
    </>
  );
};

export default reduxForm({
  form: 'preSignUp',
  enableReinitialize: true,
  initialValues: {
    registerDate: moment().format('YYYY-MM-DD'),
  },
})(PreSignUpContainer);
