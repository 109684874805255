export function maskTEL(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\D/g, "");
        v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
        v = v.replace(/(\d)(\d{4})$/, "$1-$2");
        resolve(v);
    });
}

export function maskCPF(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        resolve(v);
    });
}

export function maskCNPJ(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{2})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1/$2");
        v = v.replace(/(\d)(\d{2})$/, "$1-$2");
        resolve(v);
    });
}

export function maskIE(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        resolve(v);
    });
}

export function maskRG(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/(\d{2})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{5})(\d)/, "$1.$2");
        v = v.replace(/(\d{9})(\d)/, "$1-$2");
        resolve(v);
    });
}

export function maskCEP(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\D/g, "");
        v = v.replace(/^(\d{5})(\d)/, "$1-$2");
        resolve(v);
    });
}

export function maskCARTAO(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{4})(\d)/, "$1.$2");
        v = v.replace(/(\d{4})(\d)/, "$1.$2");
        v = v.replace(/(\d{4})(\d)/, "$1.$2");
        v = v.replace(/(\d{4})(\d)/, "$1.$2");
        resolve(v);
    });
}

export function maskDATA(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{2})(\d)/, "$1/$2");
        v = v.replace(/(\d{2})(\d)/, "$1/$2");
        resolve(v);
    });
}

export function maskVALOR(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d)(\d{8})$/, "$1.$2");
        v = v.replace(/(\d)(\d{5})$/, "$1.$2");
        v = v.replace(/(\d)(\d{2})$/, "$1,$2");
        resolve(v);
    });
}

export function maskEMAIL(v) {
    return new Promise((resolve, reject) => {
        if(!v){
            resolve("");
            return;
        }
        v = v.replace(/\s/g, "");
        resolve(v);
    });
}