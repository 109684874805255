import React from 'react';
import Radio from 'src/components/Radio';
import Select from 'src/components/Select';
import { required } from 'src/utils/validations';

const CorporateData: React.FC = () => {
  return (
    <div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="* Qual foi sua motivação para abrir seu negócio?"
          name="motivation"
          id="register-motivation"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Alternativa para o desemprego">
            Alternativa para o desemprego
          </option>
          <option value="Única alternativa para sustento">
            Única alternativa para sustento
          </option>
          <option value="Sempre quis ser dono do próprio negócio">
            Sempre quis ser dono do próprio negócio
          </option>
          <option value="Complemento da renda familiar">
            Complemento da renda familiar
          </option>
          <option value="Identifiquei uma boa oportunidade de negócio">
            Identifiquei uma boa oportunidade de negócio
          </option>
          <option value="Para trabalhar com o que eu gosto">
            Para trabalhar com o que eu gosto
          </option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="* Regime Tributário"
          name="taxRegime"
          id="register-taxRegime"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Microempreendedor individual">
            Microempreendedor individual - até R$60 mil por ano
          </option>
          <option value="Simples nacional">
            Simples nacional - até R$3,6 milhões por ano
          </option>
          <option value="Lucro presumido">
            Lucro presumido - até R$48 milhões por ano
          </option>
          <option value="Lucro real">Lucro real</option>
        </Select>
      </div>

      <div className="row">
        <Select
          className="form-group col-md-6"
          label="* Setor de atuação"
          name="industry"
          id="register-industry"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Agronegócio">Agronegócio</option>
          <option value="Comércio">Comércio</option>
          <option value="Indústria">Indústria</option>
          <option value="Serviços">Serviços</option>
        </Select>
      </div>

      <div className="row">
        <Select
          className="form-group col-md-6"
          label="* A empresa funciona a quantos anos?"
          name="companyTime"
          id="register-companyTime"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Até 1 ano">Até 1 ano</option>
          <option value="2 anos">2 anos</option>
          <option value="3 anos">3 anos</option>
          <option value="4 anos">4 anos</option>
          <option value="5 anos">5 anos</option>
          <option value="6 anos">6 anos</option>
          <option value="7 anos">7 anos</option>
          <option value="8 anos">8 anos</option>
          <option value="9 anos">9 anos</option>
          <option value="10 anos">10 anos</option>
          <option value="Mais de 10 anos">Mais de 10 anos</option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="* Número de Funcionários"
          name="employeesNumber"
          id="register-employeesNumber"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="Mais de 10">Mais de 10</option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="* De onde virá o capital para a abertura do seu negócio?"
          name="capital"
          id="register-capital"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Próprio">Próprio</option>
          <option value="Recisão Trabalhista">Recisão Trabalhista</option>
          <option value="Sócio investidor">Sócio investidor</option>
          <option value="Banco comercial">Banco comercial</option>
          <option value="Financeiras">Financeiras</option>
          <option value="Família">Família</option>
          <option value="Amigos ou Sócio">Amigos ou Sócio</option>
          <option value="Venda de bens">Venda de bens</option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="* Qual a sua maior dificuldade?"
          name="difficulty"
          id="register-difficulty"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Não possuir capital de giro próprio">
            Não possuir capital de giro próprio
          </option>
          <option value="Não ter acesso a crédito bancário">
            Não ter acesso a crédito bancário
          </option>
          <option value="Não possuir experiência como empresário">
            Não possuir experiência como empresário
          </option>
          <option value="Não ter experiência no ramo de atuação">
            Não ter experiência no ramo de atuação
          </option>
          <option value="Não conhecer ferramentas de gestão (Ex: Marketing e Vendas)">
            Não conhecer ferramentas de gestão (Ex: Marketing e Vendas)
          </option>
          <option value="Acesso a capacitação">Acesso a capacitação</option>
          <option value="Não tem tempo para se capacitar">
            Não tem tempo para se capacitar
          </option>
          <option value="Outros">Outros</option>
        </Select>
      </div>
      <Radio
        className="row"
        label="* Você já participou de alguma capacitação empresarial?"
        name="businessTraining"
        id="business-training"
        values={['Sim', 'Não']}
        validate={[required]}
      />

      <Radio
        className="row"
        label="* Você já participou de alguma ação do Sebrae?"
        name="sebrae"
        id="sebrae"
        values={['Sim', 'Não']}
        validate={[required]}
      />

      <Radio
        className="row"
        label="* Foi realizado algum tipo de plano de negócio antes de você abrir a
        empresa?"
        name="businessPlan"
        id="business-plan"
        values={['Sim', 'Não']}
        validate={[required]}
      />

      <Radio
        className="row"
        label="* Foi realizado algum tipo de estudo de mercado antes de você abrir
        o seu negócio?"
        name="marketStuding"
        id="market-studing"
        values={['Sim', 'Não']}
        validate={[required]}
      />
    </div>
  );
};

export default CorporateData;
