import api from 'axios';

export interface CnpjResponse {
  atividade_principal: { text: string; code: string }[];
  nome: string;
  cep: string;
  complemento: string;
  numero: string;
  status?: string;
}

export const findCnpj = (cnpj: string) =>
  api.post<CnpjResponse>(
    'https://us-central1-dbopen-capacita.cloudfunctions.net/getCnpj',
    { cnpj },
  );
