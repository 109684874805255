import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import MainHeader from "../MainHeader";
import withAuthorization from "../Session/withAuthorization";
import { auth, db } from "../../../firebase";
import VOLTAR from "../Images/Voltar.png";
import ReactStars from 'react-stars';
import _ from 'lodash';
import $ from "jquery";
import YouTube from 'react-youtube';
import { MAIN_SCREEN, PF_LESSONS } from "../../../constants/routes";
import swal from 'sweetalert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loading from "../Loading";
import Vimeo from "@u-wave/react-vimeo";
import qs from 'qs';

class Watch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: auth.getAuthUser(),
            lesson: [],
            relatedVideos: [],
            teacher: [],
            ratingContent: 0,
            ratingKnowledge: 0,
            ratingTeacher: 0,
            isLoading: true,
            mailLoading: false,
            showRelated: true
        }
        console.log(props)
    }

    componentDidMount() {
        if (this.props.propsState && this.props.propsState.lesson) {
            this.setState({ lesson: this.props.propsState.lesson }, () => this.getTeacher());
            if(this.props.propsState.related && this.props.propsState.related == "notShow"){
                this.setState({showRelated: false})
            }
        } else {
            const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            if (query.id) {
                db.refNode(`Classes/${query.id}`).once('value').then((lesson) => {
                    // @TODO lesson not found
                    this.setState({ lesson: lesson.val() }, () => this.getTeacher());
                }).catch((error) => {
                    // console.log('Watch componentDidMount get class error:', error);
                });
            }
        }

        if (!this.state.lesson) {
            this.props.history.push(MAIN_SCREEN);
        }

        this.getUserType();
        this.getUserEmail();
        // console.log(this.props)
    }

    getTeacher() {
        this.isRating();
        this.setTotal();
        this.themeViewed();
        if (this.state.lesson.teacherId) {
            let teacherId = this.state.lesson.teacherId;
            db.refNode(`Teachers/${teacherId}/`).once("value").then((teacher) => {
                if (teacher.val()) {
                    this.setState({ teacher: teacher.val() });
                }
            }).catch((error) => {
                // console.log("Error", error);
            });
        }
    }

    themeViewed() {
        let uid = this.state.lesson.themeId;
        if(uid){
            db.refNode(`Themes/${uid}/`).once("value").then((themeData) => {
                if (themeData.val()) {
                    const viewData = themeData.val();
                    let countView = viewData.views ? viewData.views : 0;
                    countView++;
                    db.refNode(`Themes/${uid}`).update({ views: countView });
                }
            }).catch((error) => {
                // console.log("Error", error);
            });
        }
    }

    setRelated(lessonData) {
        let themeId = lessonData.themeId;
        let videoUid = lessonData.uid;
        let videos = [];
        db.refNode(`Classes/`).orderByChild("themeId").equalTo(themeId).once("value").then((next) => {
            if (next.val()) {
                _.each(next.val(), (lesson) => {
                    if (lesson.uid != videoUid) {
                        videos = _.concat(videos, lesson);
                    }
                });
                this.setState({ relatedVideos: videos });
            }
            // console.log("Watch setRelated next.val()", next.val());

        }).catch((error) => {
            // console.log("Watch setRelated error", error);
        });
    }

    getUserType() {
        // console.log('getUserType init');
        let userUid = auth.getAuthUser().uid;
        db.refNode(`UsersBB/${userUid}/type`).once("value").then((userType) => {
            if (userType.val()) {
                this.setState({ userType: userType.val() }, () => {
                    if (this.state.lesson) {
                        this.setRelated(this.state.lesson);
                    }
                });
            }
            // console.log('Watch getUserType() userType.val()', userType.val());
        }).catch((error) => {
            // console.log("Error", error);
        });
    }

    getUserEmail() {
        let userUid = auth.getAuthUser().uid;
        db.refNode(`UsersBB/${userUid}/email`).once("value").then((userEmail) => {
            if (userEmail.val()) {
                this.setState({ userEmail: userEmail.val() });
            }
        }).catch((error) => {
            // console.log("Error", error);
        });
    }


    End = () => {
        $("#main-video").hide();
        $("#div-main-video").addClass("rating-video");
        $("#rating-hidden").show();
    }

    getVideoId = (url) => {
      if(!url)
         return;
      else{
        console.log('getVideoId url', url);
        if(url.includes("https://vimeo.com/manage/videos/")){
          return url.replace("https://vimeo.com/manage/videos/","")
        }
        if (url.includes("https://player.vimeo.com/video/")) {
          return url.replace("https://player.vimeo.com/video/","")
        }
        if (url.includes("https://vimeo.com/")) {
          return url.replace("https://vimeo.com/","")
        }
      }

    }

    saveRating() {
        $("#bt-save-rating").remove();
        $("#preloader").show();
        const userUid = auth.getAuthUser().uid;
        const uid = this.state.lesson.uid;
        db.refNode(`Classes/${uid}`).once("value").then((classData) => {
            if (classData.val()) {
                const ratingData = classData.val();
                let ratingSumContent = ratingData.ratingSumContent ? ratingData.ratingSumContent : 0;
                let ratingSumTeacher = ratingData.ratingSumTeacher ? ratingData.ratingSumTeacher : 0;
                let ratingSumKnowledge = ratingData.ratingSumKnowledge ? ratingData.ratingSumKnowledge : 0;
                let ratingCountContent = ratingData.ratingCountContent ? ratingData.ratingCountContent : 0;
                let ratingCountTeacher = ratingData.ratingCountTeacher ? ratingData.ratingCountTeacher : 0;
                let ratingCountKnowledge = ratingData.ratingCountKnowledge ? ratingData.ratingCountKnowledge : 0;
                ratingSumContent += this.state.ratingContent;
                ratingSumTeacher += this.state.ratingTeacher;
                ratingSumKnowledge += this.state.ratingKnowledge;
                ratingCountContent++;
                ratingCountTeacher++;
                ratingCountKnowledge++;
                db.refNode(`Classes/${this.state.lesson.uid}`).update({ ratingSumContent, ratingSumTeacher, ratingSumKnowledge, ratingCountContent, ratingCountTeacher, ratingCountKnowledge }).then(() => {
                    db.refNode(`UsersBB/${userUid}/ratedClasses/${uid}`).set(true);
                    this.setState({isLoading: true}, () => this.props.history.goBack());
                }).catch((error) => {
                    // console.log("Error", error);
                });
                $("#preloader").hide();
            }
        }).catch((error) => {
            // console.log("Error", error);
        });
    }

    changeRating(value, type) {
        switch (type) {
            case 1:
                this.setState({ ratingContent: value });
                break;
            case 2:
                this.setState({ ratingTeacher: value });
                break;
            case 3:
                this.setState({ ratingKnowledge: value });
                break;
        }
    }

    setTotal() {
        const ratingData = this.state.lesson;
        let ratingSumContent = ratingData.ratingSumContent ? ratingData.ratingSumContent : 0;
        let ratingSumTeacher = ratingData.ratingSumTeacher ? ratingData.ratingSumTeacher : 0;
        let ratingSumKnowledge = ratingData.ratingSumKnowledge ? ratingData.ratingSumKnowledge : 0;
        let ratingCountContent = ratingData.ratingCountContent ? ratingData.ratingCountContent : 0;
        let ratingCountTeacher = ratingData.ratingCountTeacher ? ratingData.ratingCountTeacher : 0;
        let ratingCountKnowledge = ratingData.ratingCountKnowledge ? ratingData.ratingCountKnowledge : 0;
        const totalSumRating = (ratingSumContent / ratingCountContent) + (ratingSumKnowledge / ratingCountKnowledge) + (ratingSumTeacher / ratingCountTeacher);
        let average = totalSumRating / 3;
        if (ratingSumContent == 0 || ratingSumTeacher == 0 || ratingSumKnowledge == 0 || ratingCountContent == 0 || ratingCountTeacher == 0 || ratingCountKnowledge == 0) {
            average = 0;
        }
        const ranking = (ratingCountTeacher + ratingCountContent + ratingCountKnowledge) / 3;
        this.setState({ ratingAverage: average.toFixed(2) });
        this.setState({ ratingRanking: ranking });
    }

    isRating = () => {
        const userUid = auth.getAuthUser().uid;
        const uid = this.state.lesson.uid;
        db.refNode(`UsersBB/${userUid}/ratedClasses/${uid}`).once("value").then((rating) => {
            if (rating.val()) {
                this.setState({ isRating: true });
            } else {
                this.setState({ isRating: false });
            }
            this.setState({ isLoading: false });
        }).catch((error) => {
            // console.log("Error", error);
        });
    }

    exportPDF(email, pdf) {
        this.setState({ mailLoading: true }, () => {
            $.ajax({
                type: "GET",
                url:
                    "https://us-central1-capacitampe-b1bf8.cloudfunctions.net/functionMail",
                data: {
                    email: email,
                    pdf: pdf
                },
                dataType: "JSON",
                success: () => {
                    swal({
                        text: "Email enviado com sucesso!",
                        icon: "success",
                        buttons: {
                            cancel: false,
                            confirm: true,
                        },
                        dangerMode: true,
                    });
                },
                error: () => {
                    swal({
                        text: "Erro ao enviar email!",
                        icon: "error",
                        buttons: {
                            cancel: false,
                            confirm: true,
                        },
                        dangerMode: true,
                    });
                },
                complete: () => {
                    this.setState({ mailLoading: false });
                }
            });
        });
    }

    changeLesson(lesson) {
        this.setState({ isLoading: true }, () => {
            this.setState({ lesson }, () => this.getTeacher())
            this.setRelated(lesson);
            $("#rating-hidden").hide();
            $("#main-video").show();
            $("#div-main-video").removeClass("rating-video");
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        });
    }

    render() {
        const { classes } = this.props;
        const opts = {
            playerVars: {
                autoplay: 0,
                rel: 0
            }
        };

        return (
            this.state.isLoading ?
                <Loading />
                :
                <div className="container-fluid">
                    <div className="background">
                        <MainHeader  logado={this.state.currentUser ? true : false} />
                        <div className="col-xs-12 col-md-10 col-md-offset-1">
                            <div className="col-md-8">
                                <a href="javascript:history.back()">
                                    <div className="col-lg-1 col-md-2 col-xs-3">
                                        <img
                                            className="img-responsive center-block image-back clickable"
                                            src={VOLTAR}
                                        />
                                    </div>
                                    <u className={classes.link}>Voltar</u>
                                </a>
                            </div>
                        </div>
                        {this.renderTitle()}
                        <div id="video-div">
                            <div className="col-xs-12 col-md-10 col-md-offset-1" style={{ marginTop: "3%" }}>
                                <div id="div-main-video" className="embed-responsive embed-responsive-16by9 video-tutorial">
                                    {this.renderVideo(opts)}
                                    {this.renderRating(classes)}
                                </div>
                                {this.renderGuide()}
                                {this.state.lesson.attachmentURL && this.renderAttachment()}
                                {this.renderDescription()}
                                {this.renderTeacher()}
                                {this.state.showRelated ?  this.renderRelatedLessons() : ""}
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-10 col-md-offset-1" style={{ marginBottom: '2%', marginTop: '7%' }}>
                            <div className="col-md-8">
                                <a href="javascript:history.back()">
                                    <div className="col-lg-1 col-md-2 col-xs-3">
                                        <img
                                            className="img-responsive center-block image-back"
                                            src={VOLTAR}
                                        />
                                    </div>
                                    <u className={classes.link}>Voltar</u>
                                </a>
                            </div>
                        </div>
                        {this.renderModal(classes)}
                        {this.teacherModal()}
                    </div>
                </div>
        );
    }

    renderVideo(opts) {

        return <Vimeo
                    video={this.getVideoId(this.state.lesson.videoUrl)}
                    autoplay
                    onEnd = { () => this.End()}

               />
    }

    renderModal(classes) {
        console.log('lesson', this.state.lesson)
        return <div id="guia-modal" className="modal fade" tabindex="-1" role="dialog">
            <div id="print-area" className="modal-dialog big-modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button id="modalClose" type="button" className="close not-print" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <p id="print-title" className="title-modal-min modal-title">Guia de Referência</p>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 modal-color-icon row">
                                    <div className="col-md-12 col-xs-12 mobile-div">
                                        {!this.state.mailLoading ?
                                            <div className="clickable" onClick={() => { this.exportPDF(this.state.userEmail, this.state.lesson.guideURL, classes) }}>
                                                <i className="far fa-envelope modal-icon"></i>&nbsp;&nbsp;<span className="modal-text-icon">Enviar por email ({this.state.userEmail})</span>
                                            </div>
                                            :
                                            <div className="text-center">
                                                <CircularProgress size={40} className={classes.progressMail} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12 guia-text">
                                    <object className="pdf-iframe" data={this.state.lesson.guideURL} type="application/pdf">
                                        <iframe src={this.state.lesson.guideURL}></iframe>
                                    </object>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    teacherModal() {
        return <div id="teacher-modal" className="modal fade" tabindex="-1" role="dialog">
            <div id="print-area" className="modal-dialog  big-modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button id="modalClose" type="button" className="close not-print" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <p id="print-title" className="title-modal-min modal-title">Currículo do consultor</p>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 guia-text">
                                    <object className="pdf-iframe" data={this.state.teacher.resumesURL} type="application/pdf">
                                        <iframe src={this.state.teacher.resumesURL}></iframe>
                                    </object>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    renderRelatedLessons() {
        return (
            _.size(this.state.relatedVideos) > 0 ?
                <div className="col-md-12 col-xs-12 mobile-div row" style={{ marginTop: "5%" }}>
                    <div className="col-md-12 col-xs-12">
                        <span className="title-min">Video aulas relacionadas ao tema</span><br /><br />
                    </div>
                    {
                        _.map(this.state.relatedVideos, (related) => {
                            return (
                                related ?
                                    <div className="col-md-4 col-xs-12 clickable"
                                        onClick={() => this.changeLesson(related)}>
                                        <img
                                            src={related.classPhotoURL}
                                            width="250"
                                            className="img img-responsive imgRelated"
                                        />
                                        <div className="col-md-11 row" style={{ marginBottom: "5%" }}>
                                            <span className="title-video-min">{related.className.length < 50 ? related.className : related.className.substring(0, 50) + "..."}</span>
                                        </div>
                                    </div>
                                    : ""
                            )
                        })
                    }
                </div>
                : ""
        );
    }

    renderTeacher() {
        return (
            <div className="col-md-6 col-md-offset-1 col-xs-12 mobile-div">
                <span className="title-min">Consultor</span><br /><br />
                <div className="col-md-4 col-md-offset-0 col-xs-8 col-xs-offset-2 row">
                    <img className="img-responsive img-circle img-min-circle" src={this.state.teacher.photoURL} />
                </div>
                <div className="col-md-8 col-xs-12">
                    <span className="title-min">{this.state.teacher.teacherName}</span><br />
                    <div className="col-md-12 row">
                        <span className="subtitle-min">{this.state.teacher.teacherCharge}</span>
                    </div>
                    {this.state.teacher.resumesURL ?
                        <div className="col-md-12 row">
                            <div data-toggle="modal" data-target="#teacher-modal" className="clickable">
                                <i className="far fa-file-pdf icon-pdf"></i>&nbsp;&nbsp;<span className="subtitle-link-min">Currículo do consultor</span>
                            </div>
                        </div>
                    :  ""}
                    {this.state.teacher.teacherTwitter ?
                    <div className="col-md-3 col-xs-6">
                        <a href={this.state.teacher.teacherTwitter ? this.state.teacher.teacherTwitter : "http://www.capacitampe.com.br/"} target="_blank">
                            <i className="fab fa-twitter icon-middle icon-medium icon-clickable"></i>
                        </a>
                    </div>
                    : ''}
                    {this.state.teacher.teacherInstagram ?
                    <div className="col-md-3 col-xs-6">
                        <a href={this.state.teacher.teacherInstagram ? this.state.teacher.teacherInstagram : "http://www.capacitampe.com.br/"} target="_blank">
                            <i className="fab fa-instagram icon-middle icon-medium icon-clickable"></i>
                        </a>
                    </div>
                    : ''}
                    {this.state.teacher.teacherFacebook ?
                    <div className="col-md-3 col-xs-6">
                        <a href={this.state.teacher.teacherFacebook ? this.state.teacher.teacherFacebook : "http://www.capacitampe.com.br/"} target="_blank">
                            <i className="fab fa-facebook-f icon-middle icon-medium icon-clickable"></i>
                        </a>
                    </div>
                    : ''}
                    {this.state.teacher.teacherLinkedin ?
                    <div className="col-md-3 col-xs-6">
                        <a href={this.state.teacher.teacherLinkedin ? this.state.teacher.teacherLinkedin : "http://www.capacitampe.com.br/"} target="_blank">
                            <i className="fab fa-linkedin-in icon-middle icon-medium icon-clickable"></i>
                        </a>
                    </div>
                    : ''}
                </div>
                <span className="text-min"><br />
                    {this.state.lesson.contentTeacher}
                </span>
            </div>
        );
    }

    renderDescription() {
        return <div className="col-md-5 col-xs-12 mobile-div">
            {/* <span className="title-min">Descrição da Aula</span><br /><br />
           <span className="text-min">
                {this.state.lesson.classDesc}
             </span><br />*/}
            <div className="col-md-6 col-xs-12 row">
                <ReactStars count={5} size={25} value={this.state.ratingAverage} edit={false} color2={'#ffd700'} />
            </div>
            <div className="col-md-6 col-xs-12 row" style={{ marginTop: "3%" }}>
                <span className="rating-text">{this.state.ratingAverage} ({this.state.ratingRanking} classificações)</span>
            </div>
        </div>;
    }

    renderGuide() {
        return <div data-toggle="modal" data-target="#guia-modal" className="col-md-12 col-xs-12 guia-de-referencia clickable mobile-div">
            <i className="far fa-folder icon-middle"></i> <span>Guia de Referência</span>
        </div>;
    }

    renderAttachment() {
        return <a href={this.state.lesson.attachmentURL} target="_blank" className="col-md-12 col-xs-12 class-attachments clickable mobile-div">
            <i className="fa fa-download icon-middle"></i> <span>Baixar anexo(s) complementare(s)</span>
        </a>;
    }

    renderTitle() {
        return <div className="col-md-8 col-md-offset-2 col-xs-12" style={{ textAlign: 'center', marginTop: "2%" }}>
            <span className="responsive-title">{this.state.lesson.className}</span>
        </div>;
    }

    renderRating(classes) {
        const ratingChangedContent = (newRating) => {
            this.changeRating(newRating, 1);
        }
        const ratingChangedTeacher = (newRating) => {
            this.changeRating(newRating, 2);
        }
        const ratingChangedKnowledge = (newRating) => {
            this.changeRating(newRating, 3);
        }
        return (
            <div id="rating-hidden">
                <div className="col-xs-12" style={{ marginTop: "4%" }}>
                    <div className="col-xs-12 rating-video-text">O conteúdo dessa videoaula atendeu a sua expectativa? videoaula atendeu o tema proposto?</div>
                    <div className="col-xs-12 col-lg-4 col-md-4">
                        <ReactStars
                            value={this.state.ratingContent}
                            onChange={ratingChangedContent}
                            count={5}
                            size={45}
                            // edit={!this.state.isRating}
                            color2={'#ffd700'} />
                        <span className="rating-video-pessimo">Péssimo</span>
                        <span className="rating-video-excelente">Excelente</span>
                    </div>
                </div>
                <div className="col-xs-12" style={{ marginTop: "4%" }}>
                    <div className="col-xs-12 rating-video-text">Como foi a postura do consultor durante a aula?</div>
                    <div className="col-xs-12 col-lg-4 col-md-4">
                        <ReactStars
                            value={this.state.ratingTeacher}
                            onChange={ratingChangedTeacher}
                            count={5}
                            size={45}
                            // edit={!this.state.isRating}
                            color2={'#ffd700'} />
                        <span className="rating-video-pessimo">Péssimo</span>
                        <span className="rating-video-excelente">Excelente</span>
                    </div>
                </div>
                <div className="col-xs-12" style={{ marginTop: "4%" }}>
                    <div className="col-xs-12 rating-video-text">Os conhecimentos adquiridos nessa videoaula serão aplicados no seu negócio?</div>
                    <div className="col-xs-12 col-lg-4 col-md-4">
                        <ReactStars
                            value={this.state.ratingKnowledge}
                            onChange={ratingChangedKnowledge}
                            count={5}
                            size={45}
                            // edit={!this.state.isRating}
                            color2={'#ffd700'} />
                        <span className="rating-video-excelente">Não</span>
                        <span className="rating-video-pessimo">Sim</span>
                    </div>
                </div>
                <div id="preloader" className="col-md-2 col-md-offset-5 col-xs-4 col-xs-offset-4 mobile-div div-progress">
                    <CircularProgress size={80} className={classes.progress} />
                </div>
                {/* {!this.state.isRating ? */}
                    <div id="bt-save-rating" onClick={() => this.saveRating()} className="col-md-4 col-md-offset-4 col-xs-10 col-xs-offset-1 answer clickable">
                        <span style={{ marginTop: "5%" }} className="mobile-div-10">SALVAR</span>
                    </div>
                    {/* : ""} */}
            </div>
        );
    }
}
const styles = {
    root: {
        flex: 1
    },
    body: {
        fonWeight: 100
    },
    text: {
        fontFamily: "Gotham Rounded Medium",
        fontSize: "25pt",
        color: '#505050'
    },
    link: {
        fontFamily: "Gotham Rounded Book",
        fontSize: "20pt",
        color: 'black'
    },
    progress: {
        color: '#ffd700'
    },
    progressMail: {
        color: '#17549B'
    }
};

// const authCondition = (authUser) => !!authUser;
// Watch = withAuthorization(authCondition)(Watch);
Watch = withStyles(styles)(Watch);
export default Watch;
