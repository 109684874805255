import React, { useCallback, useState, useEffect } from 'react';
import {
  InjectedFormProps,
  reduxForm,
  setSubmitFailed,
  setSubmitSucceeded,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/store';
import { SignUpState } from 'src/store/modules/SignUp/types';
import Loading from 'src/components/Loading';
import { updateUserData } from 'src/services/userService';
import swal from 'sweetalert';
import {
  startLoadingAction,
  stopLoadingAction,
} from 'src/store/modules/SignUp/actions';
import { useHistory } from 'react-router-dom';
import { SIGN_IN, MAIN_SCREEN } from 'src/constants/routes';
import CompletePreSignUpComponent from './component';
import SelectType from './components/SelectType';
import { getAuthUser } from '../../firebase/auth';
import { onceGetUsers } from '../../firebase/db';

const CompletePreSignUpContainer: React.FC<
  InjectedFormProps<unknown>
> = props => {
  const { change } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading } = useSelector<AppState, SignUpState>(state => state.signUp);

  const [type, setType] = useState<string | null>(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const authUser = getAuthUser();
    if (authUser) {
      onceGetUsers(authUser.uid).then(snapshot => {
        setUser(snapshot.val());
      });
    } else {
      history.push(SIGN_IN);
    }
  }, [history]);

  useEffect(() => {
    if (user) {
      setType(user.type);
      dispatch(change('name', user.name));
    }
  }, [change, dispatch, user]);

  const submit = useCallback(
    async (data: Record<string, any>) => {
      dispatch(startLoadingAction());

      try {
        await updateUserData(
          {
            ...data,
            preSignup: false,
          },
          user.uid,
        );
        dispatch(stopLoadingAction());
        await swal({
          title: 'Você completou seu cadastro!',
          text:
            'Agora só falta você preencher o questionário para começar sua experiência personalizada.',
          icon: 'success',
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: false,
        });
        history.push(MAIN_SCREEN);

        dispatch(setSubmitSucceeded('signUp'));
      } catch (err) {
        dispatch(stopLoadingAction());
        await swal({
          text:
            'Desculpe, ocorreu um erro ao cadastrar sua conta! Tente novamente em alguns minutos.',
          icon: 'error',
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: true,
        });
        dispatch(setSubmitFailed('signUp'));
        throw err;
      }
    },
    [dispatch, history, user?.uid],
  );

  return !user ? (
    <Loading />
  ) : !type ? (
    <SelectType selectType={setType} />
  ) : (
    <>
      {loading && <Loading />}
      <div style={{ display: loading ? 'none' : 'block' }}>
        <CompletePreSignUpComponent {...props} type={type} submit={submit} />
      </div>
    </>
  );
};

export default reduxForm({
  form: 'completePreSignUp',
  enableReinitialize: true,
  initialValues: {},
})(CompletePreSignUpContainer);
