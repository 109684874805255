import moment from 'moment';
import partners from 'src/constants/partners';
import { db, auth } from '../firebase/firebase';

export interface CreateUserDTO {
  [key: string]: any;
}

export const updateDisplayName = (displayName: string) =>
  auth.currentUser?.updateProfile({ displayName, photoURL: null });

export const userAlredyExist = async (document: string, email: string) => {
  let cleanDocument = null;
  if (document) {
    cleanDocument = document.replace(/\D/g, '');
  }

  let user = null;
  if (cleanDocument) {
    if (cleanDocument.length === 11) {
      user = await db
        .ref(`UsersBB/`)
        .orderByChild('cpf')
        .equalTo(document)
        .once('value');
    } else {
      user = await db
        .ref(`UsersBB/`)
        .orderByChild('cnpj')
        .equalTo(document)
        .once('value');
    }

    if (user && user.val()) {
      return 'cpf';
    }
  }

  user = await db
    .ref(`UsersBB/`)
    .orderByChild('email')
    .equalTo(email)
    .once('value');

  if (user && user.val()) {
    return 'email';
  }

  return undefined;
};

export const createUser = async (formData: CreateUserDTO, uid: string) => {
  const { email, name } = formData;
  await db.ref(`UsersBB/${uid}/`).set({
    email,
    uid,
    createdDateEpoch: new Date().getTime(),
  });

  const signupSnapshot = await db.ref('FreeSignupConfigBB').once('value');
  const signupConfig = signupSnapshot.val() as {
    expirationAmount: string;
    activated: boolean;
    expirationByDate: boolean;
    expirationDate: string;
    registerAmount: string;
    registerLimit: string;
  };
  let days = parseInt(signupConfig.expirationAmount, 10);
  if (!signupConfig.activated) {
    days = 180;
  }
  const userData = {
    ...formData,
    expiration_date: moment().add(days, 'days').format(),
    clientId: 'DV6Op3v71SQQuVfqplcxyoJkFz92',
  };

  await db.ref(`UsersBB/${uid}`).update(userData);
  if (name) {
    updateDisplayName(name);
  }
  // this.sendSms(email);
  let promotionalSignup = true;
  if (signupConfig.activated) {
    if (signupConfig.expirationByDate) {
      const now = moment(moment().format('DD/MM/YY'), 'DD/MM/YY');
      const expirationDate = moment(signupConfig.expirationDate, 'DD/MM/YY');
      if (now > expirationDate) {
        promotionalSignup = false;
      }
    }

    if (
      parseInt(signupConfig.registerAmount, 10) >=
      parseInt(signupConfig.registerLimit, 10)
    ) {
      promotionalSignup = false;
    }

    if (promotionalSignup) {
      db.ref('FreeSignupConfigBB').set({
        ...signupConfig,
        registerAmount: parseInt(signupConfig.registerAmount, 10) + 1,
      });
    }
  }
};

export const updateUserData = async (formData: CreateUserDTO, uid: string) => {
  delete formData.email;
  await db.ref(`UsersBB/${uid}`).update(formData);
};

export const getUserIdByEmail = async (
  email: string,
): Promise<string | undefined> => {
  const { userRefs } = partners;
  // eslint-disable-next-line no-restricted-syntax
  for await (const userRef of userRefs) {
    const user = await db
      .ref(`${userRef}/`)
      .orderByChild('email')
      .equalTo(email)
      .once('value');
    const userData = user.val();
    if (userData) {
      return Object.keys(userData)[0];
    }
  }
  return undefined;
};
