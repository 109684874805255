import styled from 'styled-components';

export const Container = styled.div`
  .title {
    font-family: Gotham Rounded Book;
    font-size: 45pt;
    color: #f5a623;
  }

  hr {
    border-color: #d6d8d8;
    border-width: 2px;
  }
`;
