import { auth } from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = password =>
  auth.currentUser.updatePassword(password);

// Get Auth User
export const getAuthUser = () => auth.currentUser;

// Boolean to check if user is logged
export const userLogged = () => auth.currentUser !== null;

export const updateDisplayName = (displayName: string) =>
  auth.currentUser.updateProfile({ displayName, photoURL: '' });
