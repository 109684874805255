import React from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import * as paths from '../constants/routes';
import Route from './Route';
import Dashboard from '../deprecated/components/Dashboard';
import Tutorial from '../deprecated/components/Tutorial';
import Quiz from '../deprecated/components/Quiz';
import MainScreen from '../deprecated/components/MainScreen';
import PFLessons from '../deprecated/components/PFLessons';
import AdditionalLessons from '../deprecated/components/AdditionalLessons';
import ViewedLessons from '../deprecated/components/ViewdLessons';
import Watch from '../deprecated/components/Watch';
import ThemeDetails from '../deprecated/components/ThemeDetails';
import CertificateAutentication from '../deprecated/components/CertificateAutentication';
import Certificate from '../deprecated/components/Certificate';
import SignUp from '../pages/SignUp';
import PreSignUp from '../pages/PreSignUp';
import CompletePreSignUp from '../pages/CompletePreSignUp';

const Routes: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="app">
      <Switch>
        <Route
          exact
          path={paths.CERTIFICATE_VOID}
          component={props => <Dashboard history={history} {...props} />}
        />
        <Route
          exact
          path={paths.CERTIFICATE_AUTENTICATION}
          component={props => (
            <CertificateAutentication history={history} {...props} />
          )}
        />
        <Route
          exact
          path={paths.START}
          component={props => <Dashboard history={history} {...props} />}
        />
        <Route
          exact
          path={paths.SIGN_IN}
          component={props => <Dashboard history={history} {...props} />}
        />
        <Route
          exact
          path={paths.SIGN_UP}
          component={props => <SignUp {...props} />}
        />
        <Route
          exact
          path={paths.PRE_SIGN_UP}
          component={props => <PreSignUp {...props} />}
        />
        <Route
          isPrivate
          exact
          path={paths.COMPLETE_PRE_SIGN_UP}
          component={props => <CompletePreSignUp {...props} />}
        />
        <Route
          exact
          path={paths.LOGIN_CODE}
          component={props => <Dashboard history={history} {...props} />}
        />
        <Route
          isPrivate
          exact
          path={paths.CERTIFICATE}
          component={props => <Certificate history={history} {...props} />}
        />
        <Route
          isPrivate
          exact
          path={paths.TUTORIAL}
          component={props => <Tutorial history={history} {...props} />}
        />
        <Route
          isPrivate
          exact
          path={paths.QUIZ}
          component={props => <Quiz history={history} {...props} />}
        />
        <Route
          isPrivate
          exact
          path={paths.MAIN_SCREEN}
          component={props => <MainScreen history={history} {...props} />}
        />
        <Route
          isPrivate
          exact
          path={paths.PF_LESSONS}
          component={props => <PFLessons history={history} {...props} />}
        />
        <Route
          isPrivate
          exact
          path={paths.ADDITIONAL_LESSONS}
          component={props => (
            <AdditionalLessons history={history} {...props} />
          )}
        />
        <Route
          isPrivate
          exact
          path={paths.VIEWED_LESSONS}
          component={props => <ViewedLessons history={history} {...props} />}
        />
        <Route
          isPrivate
          exact
          path={paths.WATCH}
          component={props => (
            <Watch propsState={location.state} history={history} {...props} />
          )}
        />
        <Route
          isPrivate
          exact
          path={paths.THEME_DETAILS}
          component={props => (
            <ThemeDetails
              propsState={location.state}
              history={history}
              {...props}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default Routes;
