import React, { Component } from "react";

class MainFooter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="row">
                <div className="col-xs-12 footer" style={{marginTop: "3%"}}>
                    <div className="row text-center">
                        <span>© 2021 Capacita-MPE, Inc.</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default (MainFooter);
