const rasd = [
  'Arniqueira',
  'Brazlândia',
  'Candangolândia',
  'Ceilândia',
  'Cruzeiro',
  'Estrutural/Scia',
  'Fercal',
  'Gama',
  'Guará',
  'Itapoã',
  'Jardim Botânico',
  'Lago Norte',
  'Lago Sul',
  'Núcleo Bandeirante',
  'Paranoá',
  'Park Way',
  'Planaltina',
  'Plano Piloto',
  'Recanto das Emas',
  'Riacho Fundo',
  'Riacho Fundo 2',
  'SIA',
  'Samambaia',
  'Santa Maria',
  'Sobradinho',
  'Sobradinho II',
  'Sol Nascente/Pôr do Sol',
  'Sudoeste/Octogonal',
  'São Sebastião',
  'Taguatinga',
  'Varjão',
  'Vicente Pires',
  'Águas Claras',
];
export const RAS = rasd.sort();
